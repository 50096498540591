import { Box, Flex } from '@chakra-ui/react'

import { useColorPalette } from 'app/providers'
import { colorPalettes } from 'shared/ui/ColorPaleteSelector'

import { Element } from './Element'
import { CustomToggleProps } from '../types/types'
import { getContrastingTextColor, lightenColor } from '../utils/utils'
import { colorThreePalette } from '../utils/data'

export const CustomToggle: React.FC<CustomToggleProps> = ({
  onClick,
  selectedNames,
  elementIndex,
  multiSelectedValues,
  isPlannerEdit,
}) => {
  const { palette } = useColorPalette()

  // const selectedColor =
  //   colorPalettes?.[palette]?.[selectedNames[0]] || '#c5c5c5'

  const selectedColor = colorThreePalette?.[selectedNames[0]] || '#c5c5c5'

  return (
    <Box cursor={isPlannerEdit ? 'pointer' : 'default'}>
      <Flex align={'center'}>
        {selectedNames.length > 0 ? (
          <>
            {selectedNames.map((el, index) => {
              const color = lightenColor(selectedColor, index * 7)
              const textColor = getContrastingTextColor(color)

              return (
                <Box key={index}>
                  <Element
                    color={color}
                    isFirst={index === 0}
                    isLast={index === selectedNames.length - 1}
                    fontColor={textColor}
                  >
                    {el}
                  </Element>
                </Box>
              )
            })}
            {multiSelectedValues.length > 0 && (
              <Flex
                borderRadius={'5px'}
                flexWrap={'wrap'}
                p={'0x 10px'}
                // bg={'#ffffe9'}
                ml="-60px"
                pl="50px"
                // border={'1px solid #13dfa5'}
              >
                {multiSelectedValues.map((el, multiIndex) => {
                  return (
                    <Flex
                      key={multiIndex}
                      justifyContent={'center'}
                      alignItems={'center'}
                      bg={'#ffffe9'}
                      border={'1px solid #dbdbdb'}
                      p="2px 15px"
                      _last={{
                        mr: '0',
                      }}
                      fontSize={'14px'}
                      _hover={{ bg: '#ffffde' }}
                    >
                      {el}
                    </Flex>
                  )
                })}
              </Flex>
            )}
          </>
        ) : (
          <Box mr="10px">
            <Element fontColor="black" color={'#e3e3e3'} isFirst={true}>
              {'Выберете элемент'}
            </Element>
          </Box>
        )}
      </Flex>
    </Box>
  )
}
