import { Box } from '@chakra-ui/react'
import { ElementProps } from '../types/types'

export const Element: React.FC<ElementProps> = ({
  children,
  color,
  fontColor = 'white',
  isFirst = false,
  isLast = false,
}): JSX.Element => {
  return (
    <Box
      color={fontColor}
      fontSize={'14px'}
      position="relative"
      w="fit-content"
      bg={color}
      p="3px 15px"
      mr={isLast ? '0' : '-10px'}
      clipPath={
        isFirst
          ? 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%)'
          : 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)'
      }
    >
      {children}
    </Box>
  )
}
