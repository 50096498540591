import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import { Box, Flex, Kbd, Input, Tooltip } from '@chakra-ui/react'

import { InputBuilderProps } from '../types/types'

// misc
import { HiMiniXCircle } from 'react-icons/hi2'

export const InputBuilder: React.FC<InputBuilderProps> = ({
  editable,
  elementId,
  initialValue,
  onBlur,
  autoFocus,
  handleChangeEditable,
  onDelete,
  isPlannerEdit,
}): JSX.Element => {
  const [value, setValue] = useState<string>(initialValue)
  const [isDeleteHover, setIsDeleteHover] = useState<boolean>(false)
  const [isBlockHover, setIsBlockHover] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    const trimmedValue = value.trim()
    onBlur(trimmedValue)
  })
  useEffect(() => {
    if (initialValue) setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (autoFocus && ref.current) ref.current.focus()
  }, [autoFocus])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input: string = e.target.value
    const filteredInput = input.replace(/[^()\.,+\-*/]/g, '')

    setIsError(input !== filteredInput)
    setValue(filteredInput)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const trimmedValue = value.trim()
      onBlur(trimmedValue)
    }
  }

  const handleClick = () => {
    if (!editable) handleChangeEditable(elementId, true)
  }

  return (
    <Box>
      {editable ? (
        <Tooltip
          label="Разрешены только символы: ( ) . , + - * /"
          isOpen={isError}
          hasArrow
          bg="red.600"
          color="white"
          placement="top"
          closeDelay={3000}
        >
          <Input
            m={'3px 0'}
            fontWeight={'bold'}
            fontSize={'16px'}
            w={'80px'}
            ref={ref}
            size="xs"
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            borderColor={isError ? 'red.500' : 'gray.200'}
            focusBorderColor={isError ? 'red.500' : 'blue.500'}
          />
        </Tooltip>
      ) : (
        <Box>
          <Flex
            position={'relative'}
            alignItems={'center'}
            // onMouseEnter={() => setIsBlockHover(true)}
            onMouseLeave={() => setIsBlockHover(false)}
          >
            <Box
              position={'relative'}
              border={'1px solid transparent'}
              p="3px 5px"
              borderRadius={'5px'}
              cursor={isPlannerEdit ? 'pointer' : 'default'}
              role="group"
              transition={'border 0.2s, box-shadow 0.2s'}
              onClick={isPlannerEdit ? () => handleClick() : null}
              _hover={
                isPlannerEdit && {
                  border: '1px solid',
                  borderColor: isDeleteHover ? 'red.500' : '#3182ce',
                  boxShadow: isDeleteHover
                    ? '0 0 0 1px red'
                    : '0 0 0 1px #3182ce',
                }
              }
            >
              {isPlannerEdit && (
                <Box
                  position="absolute"
                  top={'-22px'}
                  right={'-5px'}
                  visibility="hidden"
                  opacity={0}
                  transition="visibility 0.2s, opacity 0.2s"
                  _groupHover={{
                    visibility: 'visible',
                    opacity: 1,
                  }}
                >
                  <Flex>
                    <Box
                      p="1px 5px"
                      pl="15px"
                      _hover={{}}
                      onMouseEnter={() => setIsDeleteHover(true)}
                      onMouseLeave={() => setIsDeleteHover(false)}
                      onClick={onDelete}
                    >
                      <Box
                        color={'red.500'}
                        _hover={{ color: 'red.600' }}
                        fontSize={'18px'}
                        bg="white"
                        borderRadius={'50%'}
                      >
                        <HiMiniXCircle />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              )}
              {value
                .replace(/\s/g, '')
                .split('')
                .map((char, index) => (
                  <Kbd key={index} fontSize="18px" m="0 3px">
                    {char}
                  </Kbd>
                ))}
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  )
}
