import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError, CreateStandardPuncemRequest } from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateStandardContractMutation extends CreateStandardPuncemRequest {
  successAction?: () => void
  errorAction?: () => void
}

export function useCreateStandardContract(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateStandardContractMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      transmission_service,
      type_connection_id,
      tariff_type_id,
      tariff_subtype_id,
      price_category,
      price_category_id,
      type_loss_id,
      type_contract_id,
    }: UpdateStandardContractMutation) =>
      PuncemService.createStandardContact({
        transmission_service,
        type_connection_id,
        tariff_type_id,
        tariff_subtype_id,
        price_category,
        price_category_id,
        type_loss_id,
        type_contract_id,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateStandardContractMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные успешно обновлены',
            description: `Данные контракта успешно обновлены`,
          })
        }
        queryClient.invalidateQueries([queryKeys.puncem.standardContracts])
      },
      onError: (
        error: ExtendedAxiosError,
        variables: UpdateStandardContractMutation,
      ) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Данные контракта не были обновлены',
        })

        if (variables?.errorAction) variables?.errorAction()
      },
    },
  )
  return { mutate, isLoading }
}
