import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  useDisclosure,
  useColorModeValue,
  Slide,
} from '@chakra-ui/react'

import { NavBarMenu } from './NavBarMenu'

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { HiOutlineCog6Tooth } from 'react-icons/hi2'

interface NavBarProps {
  username: string
  menuContent?: React.ReactNode
}

export const NavBar: React.FC<NavBarProps> = (props) => {
  const { username, menuContent } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex alignItems={'center'} justifyContent={'space-between'} h={'50px'}>
          <IconButton
            size={'md'}
            _hover={{ color: 'teal.600', bg: 'gray.200' }}
            icon={
              isOpen ? (
                <CloseIcon />
              ) : (
                <Box fontSize={'25px'} position={'relative'}>
                  <HamburgerIcon
                    style={{
                      position: 'absolute',
                      top: '-13px',
                      left: '-12px',
                    }}
                  />
                </Box>
              )
            }
            aria-label={'Open Menu'}
            onClick={isOpen ? onClose : onOpen}
          />
          <Flex alignItems={'center'}>
            <Text mr="15px">{username && username}</Text>
            <Menu>
              <MenuButton
                _hover={{ color: 'teal.700', bg: 'gray.200' }}
                padding={'0 7px'}
                as={Button}
                cursor={'pointer'}
                minW={0}
              >
                <Box
                  _hover={{ bg: 'transparent' }}
                  aria-label="Toggle Color Mode"
                  _focus={{ boxShadow: 'none' }}
                  w="fit-content"
                >
                  <HiOutlineCog6Tooth size={'30px'} />
                </Box>
              </MenuButton>
              {menuContent}
            </Menu>
          </Flex>
        </Flex>
        <Slide
          direction="top"
          in={isOpen}
          style={{ zIndex: 10, top: isOpen ? '50px' : '0px' }}
        >
          <Box
            color="white"
            rounded="md"
            shadow="md"
            bg={'white'}
            height={'100vh'}
            display={isOpen ? 'block' : 'none'}
          >
            <NavBarMenu onClose={onClose} />
          </Box>
        </Slide>
      </Box>
    </>
  )
}
