import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface CreateStandartdContractFormData {
  type_contract_id?: string
  transmission_service?: boolean
  type_connection_id?: string
  tariff_type_id?: string
  tariff_subtype_id?: string
  price_category?: boolean
  price_category_id?: string
  type_loss_id?: string
  period?: Date
}

const SCHEMA_VALIDATION = yup.object().shape({
  type_contract_id: yup.string().required('Тип контракта обязательное поле'),
})

export const useCreateStandardContractForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    setError,
  } = useForm<CreateStandartdContractFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
    defaultValues: {
      transmission_service: true,
      price_category: true,
      period: new Date(),
      type_loss_id: '',
      price_category_id: '',
      type_connection_id: '',
      tariff_type_id: '',
      tariff_subtype_id: '',
      type_contract_id: '',
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    watch,
    setError,
  }
}
