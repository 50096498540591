import React from 'react'
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'

// misc
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'
import warningLogo from 'assets/svg/warning_logo.svg'
import { useDeleteStandardPeriod } from '../models/services/deleteStandardPeriodService'

interface DeleteStandardPeriodProps {
  isPopoverOpen: boolean
  hoveredCardIndex: boolean
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
  setHoveredCardIndex: React.Dispatch<React.SetStateAction<boolean>>
  containerRef: React.RefObject<HTMLDivElement>
  puncem_period_id: string
}

export const DeleteStandardPeriod: React.FC<DeleteStandardPeriodProps> = ({
  isPopoverOpen,
  setIsPopoverOpen,
  setHoveredCardIndex,
  containerRef,
  puncem_period_id,
}): JSX.Element => {
  const { mutate: deleteStandardPeriod, isLoading } = useDeleteStandardPeriod()

  const handleDeletePeriod = () => {
    deleteStandardPeriod({
      puncem_period_id: puncem_period_id,
      successAction: () => setIsPopoverOpen(false),
    })
  }

  return (
    <>
      <Popover isOpen={isPopoverOpen} onClose={() => setIsPopoverOpen(false)}>
        <PopoverTrigger>
          <Flex
            justifyContent={'flex-end'}
            ml="15px"
            className="icon-delete"
            fontSize="20px"
            cursor="pointer"
            color="#c2c2c2"
            _hover={{
              cursor: 'pointer',
              color: '#bc343f',
            }}
            onClick={() => {
              setIsPopoverOpen(true)
            }}
            onMouseEnter={() => setHoveredCardIndex(true)}
            onMouseLeave={() => setHoveredCardIndex(false)}
          >
            <HiOutlineArchiveBoxXMark />
          </Flex>
        </PopoverTrigger>
        <Portal containerRef={containerRef}>
          <PopoverContent zIndex={999}>
            <PopoverArrow />
            <PopoverHeader color="red">
              <Flex justifyContent={'center'} alignItems={'center'}>
                <Box w={'16px'} mr="5px">
                  <img src={warningLogo} alt="Warning" />
                </Box>
                <Box>Удаление периода</Box>
              </Flex>
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Flex w="100%" justifyContent="center">
                Удаить период?
              </Flex>
            </PopoverBody>
            <Flex m="10px" mt="10px" justifyContent={'flex-end'}>
              <Flex>
                <Button
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  colorScheme={'red'}
                  size={'xs'}
                  color="white"
                  display={'flex'}
                  alignItems={'center'}
                  onClick={handleDeletePeriod}
                >
                  Удалить
                  <Box fontSize={'18px'} ml={'7px'}>
                    <HiOutlineArchiveBoxXMark />
                  </Box>
                </Button>
                <Button
                  fontWeight={'500'}
                  ml="10px"
                  onClick={() => setIsPopoverOpen(false)}
                  size={'xs'}
                >
                  Отмена
                </Button>
              </Flex>
            </Flex>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}
