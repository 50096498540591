import { useMemo, useState } from 'react'
import { Button } from 'antd'

import './TestPage.scss'
import {
  DatePicker,
  DatePickerField,
  DatePickerMasked,
  FormInputBlock,
  Tooltip,
} from 'shared/ui'
import { Access } from 'app/providers'
import { ROLES } from 'shared/constants'
import { Box, Flex } from '@chakra-ui/react'
import { useGetTestPage } from './TstPageForm'
import { TreeSelectExample } from './ThreeSelectExample'
import { CascaderPicker } from './Cascader'

type Period = {
  date_from: string
  date_to: string
  id?: string
}

function checkPeriodsOverlap(
  existingPeriods: Period[],
  newPeriod: Period,
): boolean {
  let absorbedPeriods: string[] = []
  let absorbedIds: string[] = []

  for (const period of existingPeriods) {
    const existingFrom = new Date(period.date_from)
    const existingTo = new Date(period.date_to)
    const newFrom = new Date(newPeriod.date_from)
    const newTo = new Date(newPeriod.date_to)

    if (newFrom <= existingFrom && newTo >= existingTo) {
      absorbedPeriods.push(`с ${period.date_from} по ${period.date_to}`)
      if (period.id) absorbedIds.push(period.id)
    }
  }

  if (absorbedPeriods.length > 0) {
    console.log(
      `Новый период с ${newPeriod.date_from} по ${
        newPeriod.date_to
      } полностью поглощает периоды: ${absorbedPeriods.join(', ')}`,
    )
    return false
  } else return true
}

const periods: Period[] = [
  {
    date_from: '2024-01-01',
    date_to: '2024-03-01',
    id: 'groupDelivery_9',
  },
  {
    date_from: '2024-04-01',
    date_to: '2024-06-01',
    id: 'groupDelivery_11',
  },
  {
    date_from: '2024-07-01',
    date_to: '2024-12-01',
    id: 'groupDelivery_10',
  },
]

const newPeriod: Period = {
  date_from: '2010-03-01',
  date_to: '2034-08-01',
}

const result = checkPeriodsOverlap(periods, newPeriod)

export const Posts = () => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5>Posts</h5>
        <div></div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Published</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </>
  )
}

export const Post = ({ post }) => {
  return (
    <tr>
      <td>{post.title}</td>
      <td>{post.published ? '✅' : '❌'}</td>
      <td>
        <div style={{ display: 'flex' }}>
          <Access roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
            <button className="small-button">Edit</button>
            <button className="secondary small-button">Delete</button>
          </Access>
        </div>
      </td>
    </tr>
  )
}

export const TestPage = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [startDate, setStartDate] = useState(new Date())

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
    isValid,
  } = useGetTestPage()

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  return (
    <div>
      <Button size="small" type="primary">
        Button{' '}
      </Button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>end cascader</h1>
      'TestPage'
      <Box>
        <Flex
          w={'800px'}
          justifyContent={'space-between'}
          border={'1px solid red'}
        ></Flex>
      </Box>
      <Box>
        <Box w={'480px'} mt={'10px'}>
          <FormInputBlock
            titleWidth={'180px'}
            allowEdit={true}
            title={'Период'}
            edit={true}
          >
            <DatePickerMasked
              setInitialDate
              format="MM.yyyy"
              showMonthYearPicker
              name={'period_from'}
              placeholder={'Период'}
              type={'text'}
              theme="monthPicker"
              {...commonInputProps}
            />
          </FormInputBlock>
        </Box>
      </Box>
      <Posts />
      <h1>This is the testing page </h1>
      <TreeSelectExample />
    </div>
  )
}
