import { useCallback, useMemo } from 'react'
import { CellProps } from 'react-table'

import {
  BasicCommonColumn,
  BasicDateColumn,
  BasicTitle,
  HoursIntervalsPeakColumn,
  HoursPeakColumn,
} from 'shared/ui'

import {
  isDataChangedSignal,
  isLoadingSignal,
  tableUpdatedDataSignal,
} from '../../signals/signals'

import { Box, Flex } from '@chakra-ui/react'

const size = 'md'

const getRowSpanForMonth = (row, rows) => {
  const currentMonth = row.original.month
  let rowSpan = 1

  for (let i = row.index + 1; i < rows.length; i++) {
    if (rows[i].original.month === currentMonth) rowSpan++
    else break
  }

  if (row.index > 0 && rows[row.index - 1].original.month === currentMonth)
    return 0
  return rowSpan
}

export const TableStructure = ({ editMode, setTableData, tableData }) => {
  const handleChange = useCallback(
    ({ id, newValue, index }) => {
      let changedMonth = null
      let changedDayZone = null
      let oppositeDayZoneId = null

      const updatedData = tableData.map((item) => {
        if (item[`${Number(index) + 1}_dz_intervals_id`] === id) {
          changedMonth = item.month
          changedDayZone = item.day_zone

          // Добавляем изменения в основной массив данных
          return {
            ...item,
            [`${Number(index) + 1}_is_peak`]: newValue,
            [`${Number(index) + 1}_edited`]: true,
          }
        }
        return item
      })

      // Сохраняем изменения в tableUpdatedDataSignal
      const existingEntryIndex = tableUpdatedDataSignal.value.findIndex(
        (entry) => entry.dz_intervals_id === id,
      )

      if (existingEntryIndex > -1) {
        tableUpdatedDataSignal.value[existingEntryIndex].is_peak = newValue
      } else {
        tableUpdatedDataSignal.value = [
          ...tableUpdatedDataSignal.value,
          {
            dz_intervals_id: id,
            is_peak: newValue,
          },
        ]
      }

      // Обработка противоположной зоны суток
      if (newValue) {
        const oppositeDayZoneUpdate = updatedData.map((item) => {
          if (item.month === changedMonth && item.day_zone !== changedDayZone) {
            oppositeDayZoneId = item[`${Number(index) + 1}_dz_intervals_id`]
            return {
              ...item,
              [`${Number(index) + 1}_is_peak`]: false,
              [`${Number(index) + 1}_edited`]: true,
            }
          }
          return item
        })

        // Обновляем tableData для обеих зон суток
        setTableData([...oppositeDayZoneUpdate])

        // Также сохраняем изменения для противоположной зоны суток
        const oppositeEntryIndex = tableUpdatedDataSignal.value.findIndex(
          (entry) => entry.dz_intervals_id === oppositeDayZoneId,
        )

        if (oppositeEntryIndex > -1) {
          tableUpdatedDataSignal.value[oppositeEntryIndex].is_peak = false
        } else {
          tableUpdatedDataSignal.value = [
            ...tableUpdatedDataSignal.value,
            {
              dz_intervals_id: oppositeDayZoneId,
              is_peak: false,
            },
          ]
        }
      } else {
        setTableData([...updatedData])
      }

      if (!isDataChangedSignal.value) isDataChangedSignal.value = true
    },
    [tableData, setTableData],
  )

  const structure = useMemo(
    () => [
      {
        title: () => <BasicTitle size={size}>Месяц</BasicTitle>,
        accessor: 'month',
        disableSortBy: true,
        minWidth: 140,
        width: 140,
        className: 'hours-cell',
        CellComponent: ({
          cell,
          row,
          column,
          rows,
        }: CellProps<any, string>) => {
          const rowSpan = getRowSpanForMonth(row, rows)
          if (rowSpan === 0) return null

          const groupIndex = Math.floor(row.index / 2)
          const bgColor = groupIndex % 2 === 0 ? '#FAFBFF' : 'white'

          return (
            <Flex
              w={'100%'}
              h={'100%'}
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
            >
              <Flex
                w={'100%'}
                h={'44px'}
                position={'absolute'}
                top={'-10px'}
                bg={bgColor}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <BasicDateColumn
                  dateFormat="date_month_string_with_year"
                  align="left"
                  size={'lg'}
                >
                  {row.original.month}
                </BasicDateColumn>
              </Flex>
            </Flex>
          )
        },
      },
      {
        title: () => <BasicTitle size={size}>Зона суток</BasicTitle>,
        accessor: 'day_zone',
        disableSortBy: true,
        minWidth: 50,
        width: 50,
        className: 'hours-cell',
        CellComponent: ({ cell, row, column }: CellProps<any, string>) => {
          return (
            <BasicCommonColumn size={size}>
              {row.original.day_zone}
            </BasicCommonColumn>
          )
        },
      },
      ...Array.from({ length: 24 }, (_, i) => ({
        title: () => (
          <BasicTitle size={size}>{i === 11 ? 'Часы' : ''}</BasicTitle>
        ),
        accessor: `${i}_is_peak`,
        padding: 0,
        disableSortBy: true,
        className: 'hours-cell',
        CellComponent: ({ row }: CellProps<any, string>) => {
          const obj = tableData.find(
            (item) =>
              item[`${i + 1}_dz_intervals_id`] ===
              row.original[`${i + 1}_dz_intervals_id`],
          )

          return (
            <HoursIntervalsPeakColumn
              isLoading={isLoadingSignal.value}
              identifier="_dz_intervals_id"
              emptyValue
              onUpdate={handleChange}
              editMode={editMode}
              size={size}
              row={row.original}
              index={String(i)}
              value={obj[`${i + 1}_is_peak`]}
              // value={false}
            >
              <Box opacity={0}>{i + 1}</Box>
            </HoursIntervalsPeakColumn>
          )
        },
      })),
    ],
    [tableData, editMode],
  )
  return structure
}
