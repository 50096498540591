import React, { useCallback, useEffect, useMemo, useState, memo } from 'react'
import { Box, Button, Card, Flex, Text } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { useGetProductsThreeService } from 'entities/prices'
import { GetStandardPuncemFormulasResponse } from 'shared/models'
import {
  CascadePicker,
  CascadePlanner,
  EditButton,
  FormInputBlock,
  FormInputControl,
} from 'shared/ui'
import { transformTree } from 'shared/ui/Cascader/utils/utils'
import { FormulaJson } from 'shared/models'
import { accessDict, modulesDict } from 'shared/dictionary'

import { useEditStandardFormulasForm } from './EditStandardFormulasForm'
import { useUpdateStandardFormulas } from '../models/services/useUpdateStandardFormulas'
import { DeleteStandardFormulas } from '../../deleteStandardFormulas'

// misc
import { HiXMark } from 'react-icons/hi2'
import { MdOutlineSave } from 'react-icons/md'

interface EditStandardFormulasProps extends GetStandardPuncemFormulasResponse {
  puncem_standard_id: string
  puncem_period_id: string
}

export const EditStandardFormulas: React.FC<EditStandardFormulasProps> = memo(
  ({
    puncem_standard_id,
    puncem_period_id,
    name,
    default_product,
    formula_json,
    puncem_sp_id,
    puncem_np_id,
  }): JSX.Element => {
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [initialState, setInitialState] = useState({
      name,
      default_product,
      formula_json,
    })

    const [cascadeElements, setCascadeElements] = useState<{
      selectedNames: string[]
      value: string
    }>({
      selectedNames: [],
      value: '',
    })

    const [formulaPlannerElements, setFormulaPlannerElements] = useState<
      FormulaJson[]
    >([])

    const { data: threeData = [] } = useGetProductsThreeService({
      enabled: true,
    })

    const { mutate: updateStandardFormulas, isLoading: isLoadingUpdate } =
      useUpdateStandardFormulas()

    const cascadePickerKey = useMemo(() => String(new Date()), [isEdit])

    useEffect(() => {
      setInitialState({
        name,
        default_product,
        formula_json,
      })
    }, [name, default_product, formula_json])

    useEffect(() => {
      if (initialState?.default_product) {
        setCascadeElements({
          selectedNames: initialState?.default_product,
          value:
            initialState?.default_product[
              initialState?.default_product.length - 1
            ],
        })
      }
    }, [initialState?.default_product])

    useEffect(() => {
      setFormulaPlannerElements(initialState?.formula_json)
    }, [initialState?.formula_json])

    useEffect(() => {
      reset({
        name: initialState?.name,
        default_product: initialState?.default_product,
        formula_json: initialState?.formula_json,
      })
    }, [initialState])

    useEffect(() => {
      setValue('formula_json', formulaPlannerElements, { shouldDirty: true })
      trigger('formula_json')
    }, [formulaPlannerElements])

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
      trigger,
      getValues,
    } = useEditStandardFormulasForm()

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const handleSetValues = useCallback(
      ({ selectedNames, value }) => {
        setCascadeElements({ ...cascadeElements, selectedNames, value })
        setValue('default_product', selectedNames, { shouldDirty: true })
        trigger('default_product')
      },
      [setValue, trigger],
    )

    const productsThreeData = useMemo(() => {
      return transformTree(
        threeData?.filter((el) => el?.structure === 'products'),
      )
    }, [threeData])

    const transformedThreeData = useMemo(
      () =>
        transformTree(threeData || []).filter((item) => {
          return item?.value !== 'Пользовательские' && item?.value !== 'ПУНЦЭМ'
        }),
      [threeData],
    )

    const cascadePickerProps = {
      handleSetValues,
      selectedNames: cascadeElements?.selectedNames,
      value: cascadeElements?.value,
      isEdit,
    }

    const formulaPlannerProps = {
      elements: formulaPlannerElements,
      setElements: setFormulaPlannerElements,
      isPlannerEdit: isEdit,
    }

    const onSubmit = useCallback(() => {
      const formData = getValues()
      updateStandardFormulas({
        default_product: formData?.default_product,
        formula_json: formData?.formula_json as FormulaJson[],
        name: formData?.name,
        puncem_sp_id,
        puncem_standard_id,
        puncem_period_id,
        successAction: () => {
          setIsEdit(false)
          handleResetForm()
        },
      })
    }, [getValues])

    const handleResetForm = () => {
      reset(initialState)
      setCascadeElements({
        selectedNames: initialState.default_product || [],
        value:
          initialState.default_product?.[
            initialState.default_product.length - 1
          ] || '',
      })
      setFormulaPlannerElements(initialState?.formula_json || [])
    }

    const handleEditClick = useCallback(() => {
      setIsEdit(!isEdit)
      handleResetForm()
    }, [isEdit])

    const deleteFormulaProps = {
      puncem_sp_id,
      name,
      puncem_standard_id,
      puncem_period_id,
      isEdit,
      puncem_np_id,
    }

    return (
      <>
        <Card
          m="10px 0"
          p="10px"
          boxShadow={'none'}
          variant={'outline'}
          position={'relative'}
          bg={isEdit ? '#fff6a813' : '#afafaf13'}
          transition={'all 0.2s ease-in-out'}
          _hover={{
            border: '1px solid #11b8a2',
            boxShadow: 'md',
          }}
        >
          <Access
            permissions={[accessDict.update_read]}
            module={modulesDict.reference_books}
          >
            <Flex
              w="auto"
              justifyContent="flex-end"
              position={'absolute'}
              right={'20px'}
              top={'10px'}
              zIndex={1}
            >
              <EditButton
                handleEditClick={handleEditClick}
                isEdit={isEdit}
                size={'xs'}
              />
            </Flex>
          </Access>

          <>
            <Flex mb="5px">
              <Box w="500px">
                <FormInputBlock
                  titleWidth={'130px'}
                  allowEdit={true}
                  edit={isEdit}
                  title={'Наименование:'}
                  value={name}
                >
                  <FormInputControl
                    isRequired={true}
                    name="name"
                    placeholder="Наименование"
                    type="text"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>

              <Flex alignItems={'center'} ml="25px">
                <Text
                  color="#718096"
                  fontSize={'14px'}
                  fontWeight={'500'}
                  mr="15px"
                >
                  Товар по умолчанию:
                </Text>
                <CascadePicker
                  {...cascadePickerProps}
                  data={productsThreeData}
                  key={cascadePickerKey}
                />
              </Flex>
            </Flex>
            <Box>
              <Text color="#718096" fontSize={'14px'} fontWeight={'500'}>
                Формула расчета:
              </Text>
              <Box background="white">
                <CascadePlanner
                  {...formulaPlannerProps}
                  transformedData={transformedThreeData}
                  key={cascadePickerKey}
                />
              </Box>
            </Box>
          </>
          <Flex alignItems={'center'} justifyContent={'flex-end'} mt="10px">
            <Box mr="10px">
              <Flex justifyContent="flex-start">
                {isEdit && isDirty && (
                  <>
                    <Button
                      size="xs"
                      type="submit"
                      isLoading={isLoadingUpdate}
                      isDisabled={!isValid}
                      bg="teal.400"
                      color="white"
                      display="flex"
                      alignItems="center"
                      _hover={{
                        bg: 'teal.500',
                      }}
                      onClick={onSubmit}
                    >
                      Сохранить формулу расчета
                      <Box fontSize={'18px'} ml={'5px'}>
                        <MdOutlineSave />
                      </Box>
                    </Button>
                    <Button ml="10px" size="xs" onClick={handleEditClick}>
                      Отмена
                      <Box fontSize={'18px'} ml={'5px'}>
                        <HiXMark />
                      </Box>
                    </Button>
                  </>
                )}
                {isEdit && <DeleteStandardFormulas {...deleteFormulaProps} />}
              </Flex>
            </Box>
          </Flex>
        </Card>
      </>
    )
  },
)
