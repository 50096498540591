import { AxiosPromise } from 'axios'

import {
  GetStandardPuncemResponse,
  UpdateStandardPuncemRequest,
  CreateStandardPuncemRequest,
  GetStandardPuncemPeriodsResponse,
  CreateStandardPuncemPeriodsRequest,
  UpdateStandardPuncemPeriodsRequest,
  GetStandardPuncemFormulasResponse,
  GetStandardPuncemFormulasRequest,
  UpdateStandardPuncemFormulasRequest,
  CreateStandardPuncemFormulasRequest,
  GetCustomPuncemResponse,
  UpdateCustomPuncemRequest,
  CreateCustomPuncemRequest,
  DeleteCustomPuncemRequest,
  UpdateCustomPuncemFormulasRequest,
  GetCustomPuncemFormulasResponse,
  GetCustomPuncemFormulasRequest,
  CreateCustomPuncemFormulasRequest,
} from 'shared/models'

import { ServiceBase } from './serviceBase'

export class PuncemService extends ServiceBase {
  protected static BASE_PATH = 'puncem'

  public static updateStandardContact({
    type_contract_id,
    transmission_service,
    type_connection_id,
    tariff_type_id,
    tariff_subtype_id,
    price_category,
    price_category_id,
    type_loss_id,
    puncem_standard_id,
  }: UpdateStandardPuncemRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_puncem_standards/${puncem_standard_id}`,
      {
        type_contract_id,
        transmission_service,
        type_connection_id,
        tariff_type_id,
        tariff_subtype_id,
        price_category,
        price_category_id,
        type_loss_id,
      },
    )
  }

  public static createStandardContact({
    transmission_service,
    type_connection_id,
    tariff_type_id,
    tariff_subtype_id,
    price_category,
    price_category_id,
    type_loss_id,
    type_contract_id,
  }: CreateStandardPuncemRequest): AxiosPromise<undefined> {
    return this.post<undefined>(`${this.BASE_PATH}/create_puncem_standards`, {
      transmission_service,
      type_connection_id,
      tariff_type_id,
      tariff_subtype_id,
      price_category,
      price_category_id,
      type_loss_id,
      type_contract_id,
    })
  }

  public static getPuncemStandardContracts(): AxiosPromise<
    GetStandardPuncemResponse[]
  > {
    return this.get<GetStandardPuncemResponse[]>(
      `${this.BASE_PATH}/list_puncem_standards`,
    )
  }

  public static deletePuncemStandardContract({
    puncem_standard_id,
  }: {
    puncem_standard_id: string
  }): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/puncem_standards/${puncem_standard_id}`,
    )
  }

  public static getPuncemStandardContractPeriods(): AxiosPromise<
    GetStandardPuncemPeriodsResponse[]
  > {
    return this.get<GetStandardPuncemPeriodsResponse[]>(
      `${this.BASE_PATH}/list_puncem_periods`,
    )
  }

  public static createStandardPeriod({
    date_from,
    date_to,
  }: CreateStandardPuncemPeriodsRequest): AxiosPromise<undefined> {
    return this.post<undefined>(`${this.BASE_PATH}/create_puncem_periods`, {
      date_from,
      date_to,
    })
  }

  public static deleteStandardPeriod({
    puncem_period_id,
  }: {
    puncem_period_id: string
  }): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/puncem_periods/${puncem_period_id}`,
    )
  }

  public static updateStandardPeriod({
    date_from,
    date_to,
    puncem_period_id,
  }: UpdateStandardPuncemPeriodsRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_puncem_periods/${puncem_period_id}`,
      {
        date_from,
        date_to,
        puncem_period_id,
      },
    )
  }

  public static getPuncemStandardFormulas({
    puncem_standard_id,
    puncem_period_id,
  }: GetStandardPuncemFormulasRequest): AxiosPromise<
    GetStandardPuncemFormulasResponse[]
  > {
    return this.get<GetStandardPuncemFormulasResponse[]>(
      `${this.BASE_PATH}/list_puncem_standards/${puncem_standard_id}/periods/${puncem_period_id}`,
    )
  }

  public static updateStandardFormulas({
    name,
    default_product,
    formula_json,
    puncem_sp_id,
  }: UpdateStandardPuncemFormulasRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_puncem_standards_by_periods/${puncem_sp_id}`,
      {
        name,
        default_product,
        formula_json,
      },
    )
  }

  public static createStandardFormulas({
    puncem_period_id,
    puncem_standard_id,
    name,
    default_product,
    formula_json,
  }: CreateStandardPuncemFormulasRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/create_puncem_standards/${puncem_standard_id}/periods/${puncem_period_id}`,
      {
        name,
        default_product,
        formula_json,
      },
    )
  }

  public static deleteStandardFormula({
    puncem_np_id,
  }: {
    puncem_np_id: string
  }): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/delete_puncem_standards_by_periods/${puncem_np_id}`,
    )
  }

  public static getPuncemCustomContracts(): AxiosPromise<
    GetCustomPuncemResponse[]
  > {
    return this.get<GetCustomPuncemResponse[]>(
      `${this.BASE_PATH}/list_puncem_users`,
    )
  }

  public static updateCustomContact({
    puncem_user_id,
    puncem_name,
  }: UpdateCustomPuncemRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_puncem_users/${puncem_user_id}`,
      {
        puncem_name,
      },
    )
  }

  public static createCustomContact({
    puncem_name,
  }: CreateCustomPuncemRequest): AxiosPromise<undefined> {
    return this.post<undefined>(`${this.BASE_PATH}/create_puncem_users`, {
      puncem_name,
    })
  }

  public static deletePuncemCustomContract({
    puncem_user_id,
  }: DeleteCustomPuncemRequest): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/delete_puncem_users/${puncem_user_id}`,
    )
  }

  public static updateCustomFormulas({
    name,
    default_product,
    formula_json,
    puncem_uf_id,
  }: UpdateCustomPuncemFormulasRequest): AxiosPromise<undefined> {
    return this.patch<undefined>(
      `${this.BASE_PATH}/update_puncem_users_formulas/${puncem_uf_id}`,
      {
        name,
        default_product,
        formula_json,
      },
    )
  }

  public static getPuncemCustomFormulas({
    puncem_user_id,
  }: GetCustomPuncemFormulasRequest): AxiosPromise<
    GetCustomPuncemFormulasResponse[]
  > {
    return this.get<GetCustomPuncemFormulasResponse[]>(
      `${this.BASE_PATH}/list_puncem_users_formulas/${puncem_user_id}`,
    )
  }

  public static createCustomFormulas({
    puncem_user_id,
    name,
    default_product,
    formula_json,
  }: CreateCustomPuncemFormulasRequest): AxiosPromise<undefined> {
    return this.post<undefined>(
      `${this.BASE_PATH}/create_puncem_users_formulas/${puncem_user_id}`,
      {
        name,
        default_product,
        formula_json,
      },
    )
  }

  public static deleteCustomFormula({
    puncem_uf_id,
  }: {
    puncem_uf_id: string
  }): AxiosPromise<undefined> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/delete_puncem_users_formulas/${puncem_uf_id}`,
    )
  }
}
