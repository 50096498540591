import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'
import { Box, Flex, Button } from '@chakra-ui/react'

import { toasts } from 'shared/utils'

import { InputBuilder } from './InputBuilder'
import { AddElementMenu } from './AddElementMenu'
import { Builder } from './Builder'
import { formulaDataSignal } from '../signals/signals'
import {
  CascadePlannerProps,
  ElementsState,
  ElementTypes,
} from '../types/types'

// misc
import {
  HiDocumentDuplicate,
  HiOutlineDocumentDuplicate,
} from 'react-icons/hi2'
import { MdContentPasteGo } from 'react-icons/md'
import { Tooltip } from 'shared/ui'

export const CascadePlanner: React.FC<CascadePlannerProps> = ({
  elements,
  setElements,
  isPlannerEdit,
  transformedData,
}): JSX.Element => {
  const [coped, setCoped] = useState<boolean>(false)

  const elementsData = {
    [ElementTypes.input]: InputBuilder,
    [ElementTypes.builder]: Builder,
  }

  useEffect(() => {
    if (coped) setTimeout(() => setCoped(false), 1500)
  }, [coped])

  const handleAddNewElement = useCallback((addedType: ElementTypes) => {
    setElements((prev: ElementsState[]) => [
      ...prev,
      {
        type: addedType,
        value: '',
        selectedNames: [],
        multiSelectedValues: [],
        elementId: uuidv4(),
        editable: true,
        multi: false,
        autoFocus: true,
      },
    ])
  }, [])

  const handleElementBlur = useCallback((id: string, value: string) => {
    setElements((prev: ElementsState[]) =>
      value === ''
        ? prev.filter((el) => el.elementId !== id)
        : prev.map((el) =>
            el.elementId === id
              ? { ...el, value, editable: false, autoFocus: false }
              : el,
          ),
    )
  }, [])

  const handleDeleteElement = useCallback((id: string) => {
    setElements((prev: ElementsState[]) =>
      prev.filter((el) => el.elementId !== id),
    )
  }, [])

  const handleChangeEditable = useCallback((id: string, edit: boolean) => {
    setElements((prev: ElementsState[]) =>
      prev.map((el) =>
        el.elementId === id ? { ...el, editable: edit, autoFocus: edit } : el,
      ),
    )
  }, [])

  const handleSetValues = useCallback(
    ({ elementId, value, selectedNames, multiSelectedValues, multi }) => {
      setElements((prev: ElementsState[]) =>
        prev.map((el) =>
          el.elementId === elementId
            ? { ...el, value, selectedNames, multiSelectedValues, multi }
            : el,
        ),
      )
    },
    [],
  )

  const onDragEnd = (result: any) => {
    const { source, destination } = result

    if (!destination) return

    const reorderedElements = Array.from(elements)
    const [removed] = reorderedElements.splice(source.index, 1)
    reorderedElements.splice(destination.index, 0, removed)

    setElements(reorderedElements)
  }

  const handleCopyPlanner = () => {
    formulaDataSignal.value = elements
    setCoped(true)
    toasts.info({
      title: 'Скопировано!',
      description: 'Значение успешно скопировано в буфер обмена.',
      duration: 1500,
    })
  }

  const handlePastePlanner = () => {
    setElements(formulaDataSignal.value)
  }

  return (
    <Box
      border={isPlannerEdit ? '2px solid #e4e4e4' : '2px solid #f0f0f0'}
      m="6px 0"
      p="10px 10px"
      borderRadius="8px"
      // _hover={{
      //   border: '1px solid',
      //   borderColor: isPlannerEdit ? '#3182ce' : '#f0f0f0',
      //   boxShadow: isPlannerEdit ? '0 0 0 1px #3182ce' : '',
      // }}
      transition={'all 0.2s ease-in-out'}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-elements" direction="horizontal">
          {(provided) => (
            <Flex
              flexWrap={'wrap'}
              alignItems={'center'}
              gap={'5px'}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {elements.map((element: ElementsState, index) => {
                const Component = elementsData[element.type]

                return (
                  <Draggable
                    key={element.elementId}
                    draggableId={element.elementId}
                    isDragDisabled={!isPlannerEdit}
                    index={index}
                  >
                    {(provided) => {
                      return (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          position="relative"
                          display="flex"
                          alignItems="center"
                        >
                          <Component
                            {...element}
                            initialValue={element?.value}
                            transformedData={transformedData}
                            onBlur={(value: string) =>
                              handleElementBlur(element.elementId, value)
                            }
                            elementIndex={String(index)}
                            handleSetValues={handleSetValues}
                            handleChangeEditable={handleChangeEditable}
                            isPlannerEdit={isPlannerEdit}
                            onDelete={() =>
                              handleDeleteElement(element.elementId)
                            }
                          />
                        </Box>
                      )
                    }}
                  </Draggable>
                )
              })}
              {provided.placeholder}
              {isPlannerEdit && (
                <>
                  <AddElementMenu onAddElement={handleAddNewElement} />
                  <Flex alignItems={'center'} ml={'auto'}>
                    {formulaDataSignal?.value && (
                      <Tooltip
                        delayShow={300}
                        id={`past`}
                        content="Вставить в формулу"
                        place="top"
                      >
                        <Button
                          m="3px 0"
                          mr="5px"
                          size={'xs'}
                          colorScheme={'blue'}
                          onClick={() => handlePastePlanner()}
                          fontSize={'18px'}
                        >
                          <MdContentPasteGo />
                        </Button>
                      </Tooltip>
                    )}

                    {elements.length > 0 && (
                      <>
                        <Tooltip
                          delayShow={300}
                          id={`copy`}
                          content="Скопировать формулу"
                          place="top"
                        >
                          <Button
                            m="3px 0"
                            mr="5px"
                            size={'xs'}
                            colorScheme={coped ? 'green' : 'gray'}
                            onClick={() => handleCopyPlanner()}
                            fontSize={'18px'}
                          >
                            {coped ? (
                              <HiDocumentDuplicate />
                            ) : (
                              <HiOutlineDocumentDuplicate />
                            )}
                          </Button>
                        </Tooltip>
                      </>
                    )}

                    <Button
                      isDisabled={elements.length === 0}
                      m="3px 0"
                      size={'xs'}
                      colorScheme="red"
                      onClick={() => setElements([])}
                    >
                      Очистить
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}
