import React, { memo, useState } from 'react'

import { Box, Button, Card, Flex, Input } from '@chakra-ui/react'
import { FormInputBlock } from 'shared/ui'

// misc

import { LuFilter, LuFilterX } from 'react-icons/lu'
import { HiOutlineEye } from 'react-icons/hi2'

interface PuncemCustomListFiltersProps {
  filters: string
  setFilters: React.Dispatch<React.SetStateAction<string>>
}

export const PuncemCustomListFilters: React.FC<PuncemCustomListFiltersProps> =
  memo(({ setFilters, filters }): JSX.Element => {
    const [filterValue, setFilterValue] = useState<string>(filters || '')

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setFilterValue(value)
    }

    const handleResetFilters = () => {
      setFilterValue('')
      setFilters('')
    }

    const handleSetFilters = () => {
      setFilters(filterValue)
    }

    return (
      <Card
        p="5px"
        boxShadow={'none'}
        variant={'filled'}
        bg="white"
        borderBottom={'1px solid #e2e8f0'}
        borderRadius={'0'}
      >
        <Card variant={'filled'} p="5px">
          <Flex alignItems={'center'} pl="15px">
            <Flex alignItems={'center'} position={'relative'}>
              <Box mr="3px" mb="2px" color={filters ? 'green' : 'black'}>
                <LuFilter opacity={filters ? 1 : 0.2} fontSize={'12px'} />
              </Box>

              <FormInputBlock
                titleWidth={'100px'}
                allowEdit={true}
                title={'Наименование'}
                edit={true}
                value={''}
                titleColor={filters ? 'green' : 'black'}
              >
                <Input
                  w="380px"
                  type="text"
                  fontSize={'14px'}
                  ml="10px"
                  placeholder="Введите наименование"
                  borderRadius={'6px'}
                  h={'25px'}
                  background="white"
                  size={'xs'}
                  value={filterValue}
                  border="1px solid #CCD7E3"
                  onChange={handleInputChange}
                />
              </FormInputBlock>
            </Flex>
            <Flex ml="15px">
              <Button
                onClick={handleSetFilters}
                size={'xs'}
                colorScheme="blue"
                isDisabled={!filterValue}
              >
                Показать
                <Box fontSize={'18px'} ml={'5px'}>
                  <HiOutlineEye />
                </Box>
              </Button>
              {filters && (
                <Button
                  ml="10px"
                  onClick={handleResetFilters}
                  size={'xs'}
                  border="1px solid lightgray"
                >
                  Очистить
                  <Box ml="5px">
                    <LuFilterX fontSize={'16px'} />
                  </Box>
                </Button>
              )}
            </Flex>
          </Flex>
        </Card>
      </Card>
    )
  })
