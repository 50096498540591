import { useQuery } from 'react-query'

import { GetCustomPuncemResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PuncemService } from 'shared/services'

interface UseGetPuncemCustomContractsResult {
  data: GetCustomPuncemResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetPuncemCustomContracts {
  enabled: boolean
}

export function useGetPuncemCustomContracts({
  enabled,
}: UseGetPuncemCustomContracts): UseGetPuncemCustomContractsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GetCustomPuncemResponse[]
  >({
    queryKey: [queryKeys.puncem.customContracts],
    queryFn: () =>
      PuncemService.getPuncemCustomContracts().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  return { data, isError, isFetching, isLoading }
}
