import { useQuery } from 'react-query'

import { GetStandardPuncemResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PuncemService } from 'shared/services'

interface UseGetPuncemStandardContractsResult {
  data: GetStandardPuncemResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetPuncemStandardContracts {
  enabled: boolean
}

export function useGetPuncemStandardContracts({
  enabled,
}: UseGetPuncemStandardContracts): UseGetPuncemStandardContractsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GetStandardPuncemResponse[]
  >({
    queryKey: [queryKeys.puncem.standardContracts],
    queryFn: () =>
      PuncemService.getPuncemStandardContracts().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  return { data, isError, isFetching, isLoading }
}
