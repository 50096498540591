import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import {
  ExtendedAxiosError,
  GetStandardPuncemPeriodsResponse,
} from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteStandardFormula {
  puncem_standard_id: string
  puncem_period_id: string
  puncem_sp_id: string
  puncem_np_id: string
  successAction: () => void
}
export function useDeleteStandardFormula(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteStandardFormula, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteStandardFormula
  >(
    ({ puncem_np_id }: DeleteStandardFormula) =>
      PuncemService.deleteStandardFormula({
        puncem_np_id,
      }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteStandardFormula) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Формула удалена',
          description: `Формула расчета успешно удалена`,
        })

        const cachedData = queryClient.getQueryData<
          GetStandardPuncemPeriodsResponse[]
        >([queryKeys.puncem.standardContractsPeriods])

        if (cachedData) {
          cachedData.forEach(({ puncem_period_id }) => {
            queryClient.invalidateQueries([
              queryKeys.puncem.standardContractsFormulas,
              variables?.puncem_standard_id,
              puncem_period_id,
            ])
          })
        }
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Формула расчета не была удалена',
        })
      },
    },
  )

  return { mutate, isLoading }
}
