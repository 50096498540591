export const lightenColor = (color: string, percent: number): string => {
  let num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

export function transformTree(data: any): any {
  return data.map((item) => ({
    label: item.node_name,
    // value: item.node_name.toLowerCase(),
    value: item.node_name,
    node_id: item.node_id,
    node_name: item.node_name,
    structure: item.structure,
    ...(item.child ? { children: transformTree(item.child) } : {}),
  }))
}
