import { useQuery } from 'react-query'

import { ExtendedAxiosError, LossesTypesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface LossesTypesData {
  value: string
  label: string
}

interface UseLossesTypesResult {
  data: LossesTypesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
  lossesTypesData: LossesTypesData[] | undefined
}

interface UseGetLossesTypes {
  enabled: boolean
  successAction?: () => void
  errorAction?: () => void
}

export function useGetLossesTypesService({
  enabled,
  successAction,
  errorAction,
}: UseGetLossesTypes): UseLossesTypesResult {
  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    LossesTypesResponse[]
  >({
    queryKey: [queryKeys.prices.lossesTypes],
    queryFn: () =>
      PriceCategoriesService.getLossesTypes().then((response) => response.data),
    enabled,
    onError: (error: ExtendedAxiosError) => {
      if (errorAction) errorAction()
      toasts.error({
        title: 'Произошла ошибка',
        description:
          error?.response?.data?.detail ||
          `Данные 'Вид потерь' не были загружены`,
      })
    },
    onSuccess: () => {
      if (successAction) successAction()
    },
  })

  const lossesTypesData = data?.map((losses) => ({
    value: losses.type_loss_id,
    label: losses.type_loss_name,
  }))

  return {
    data: data,
    isError,
    isFetching,
    isLoading,
    refetch,
    lossesTypesData,
  }
}
