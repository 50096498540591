import { useCallback, useMemo, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import {
  ProductsListV2,
  ElementsCK,
  PuncemStandardList,
  PuncemStandardActions,
} from 'widgets'

import { useGetTypesContracts } from 'entities/counteragentsContracts'
import {
  useGetLossesTypesService,
  useGetPriceCategoriesService,
  useGetTariffSubTypesService,
  useGetTariffTypesService,
  useGetTypesConnectionService,
} from 'entities/prices'

import { PuncemType } from '../types/types'
import {
  PuncemCustomActions,
  PuncemCustomList,
  StandardContractPeriods,
} from 'widgets/priceCategories'

export const PriceCategoriesPage: React.FC = (): JSX.Element => {
  const [puncemType, setPuncemType] = useState<PuncemType>(PuncemType.standard)
  const [standardContractFilters, setStandardContractFilters] = useState<{
    [key: string]: string
  }>(null)
  const [customContractFilters, setCustomContractFilters] =
    useState<string>(null)

  const { isOpen: isCreateStandardOpen, onToggle: onCreateStandardToggle } =
    useDisclosure()

  const { isOpen: isCreateCustomOpen, onToggle: onCreateCustomToggle } =
    useDisclosure()

  const { isOpen: isOpenFilters, onToggle: onToggleFilters } = useDisclosure()

  const { isOpen: isOpenCustomFilters, onToggle: onToggleCustomFilters } =
    useDisclosure()

  const {
    isOpen: isOpenPeriods,
    onToggle: onTogglePeriods,
    onClose: onClosePeriods,
  } = useDisclosure()

  const { isOpen: isVisibleAlert, onClose: onCloseAlert } = useDisclosure({
    defaultIsOpen: true,
  })

  // NOTE: Виды договоров
  const { typesContractsData } = useGetTypesContracts({
    enabled: true,
  })

  // NOTE: Вид присоеденения
  const { typesConnectionData } = useGetTypesConnectionService({
    enabled: true,
  })

  // NOTE: Типа тарифа
  const { tariffTypesData } = useGetTariffTypesService({
    enabled: true,
  })

  // NOTE: Подтип тарифа
  const { tariffSubTypesData } = useGetTariffSubTypesService({
    enabled: true,
  })

  // NOTE: Ценовые категории
  const { priceCategoriesData } = useGetPriceCategoriesService({
    enabled: true,
  })

  // NOTE: Вид потерь
  const { lossesTypesData } = useGetLossesTypesService({
    enabled: true,
  })

  const customContractProps = {
    isOpenCustomFilters,
    onToggleCustomFilters,
    isCreateCustomOpen,
    onCreateCustomToggle,
    filters: customContractFilters,
    setFilters: setCustomContractFilters,
  }

  const standardContractProps = useMemo(() => {
    return {
      isOpenFilters,
      typesContractsData,
      typesConnectionData,
      tariffTypesData,
      tariffSubTypesData,
      priceCategoriesData,
      lossesTypesData,
      onToggleFilters,
      isCreateStandardOpen,
      onCreateStandardToggle,
      filters: standardContractFilters,
      setFilters: setStandardContractFilters,
    }
  }, [
    typesContractsData,
    typesConnectionData,
    tariffTypesData,
    tariffSubTypesData,
    priceCategoriesData,
    lossesTypesData,
    onToggleFilters,
    isOpenFilters,
    isCreateStandardOpen,
    onCreateStandardToggle,
    standardContractFilters,
    setStandardContractFilters,
  ])

  const tabData = useMemo(
    () => [
      {
        type: PuncemType.standard,
        label: 'Стандартные',
        content: <PuncemStandardList {...standardContractProps} />,
      },
      {
        type: PuncemType.custom,
        label: 'Пользовательские',
        content: <PuncemCustomList {...customContractProps} />,
      },
    ],
    [standardContractProps],
  )

  const handleTabChange = useCallback((type: PuncemType) => {
    setPuncemType(type)
  }, [])

  const standardActionsProps = {
    isOpen: isCreateStandardOpen,
    onToggle: onCreateStandardToggle,
    onToggleFilters,
    isOpenFilters,
    filters: standardContractFilters,
    setFilters: setStandardContractFilters,
    onTogglePeriods,
    isOpenPeriods,
  }

  const customActionsProps = {
    isOpen: isCreateCustomOpen,
    onToggle: onCreateCustomToggle,
    onToggleCustomFilters,
    isOpenCustomFilters,
    filters: customContractFilters,
    setFilters: setCustomContractFilters,
    onTogglePeriods,
    isOpenPeriods,
  }

  return (
    <Tabs>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Товары</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Элементы ЦК</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>ПУНЦЭМ</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <ProductsListV2 />
        </TabPanel>
        <TabPanel padding={0}>
          <ElementsCK />
        </TabPanel>
        <TabPanel padding={0}>
          <Box p="2">
            <Box
              mt="10px"
              border="1px solid #dfe0eb"
              borderRadius="5px"
              maxH="90vh"
              overflowY="hidden"
              pb="500px"
            >
              <StandardContractPeriods
                isOpen={isOpenPeriods}
                onToggle={onTogglePeriods}
                onClosePeriods={onClosePeriods}
              />
              <Flex alignItems={'center'} p="2" position={'relative'}>
                {tabData.map((tab, index) => (
                  <Button
                    key={index}
                    size="xs"
                    w="200px"
                    minW={'200px'}
                    borderRadius={0}
                    border="1px solid #ececec"
                    borderLeftRadius={index === 0 ? '5px' : 0}
                    borderRightRadius={index === tabData.length - 1 ? '5px' : 0}
                    _hover={{ bg: '#3285d2', color: 'white' }}
                    bg={puncemType === tab.type ? '#2d70af' : '#f8f8f8'}
                    color={puncemType === tab.type ? 'white' : 'black'}
                    onClick={() => handleTabChange(tab.type)}
                  >
                    {tab.label}
                  </Button>
                ))}
                {
                  <>
                    {puncemType === PuncemType.standard && (
                      <>
                        {isVisibleAlert && (
                          <Alert
                            top={'3px'}
                            left={'500px'}
                            status="info"
                            p="5px 10px"
                            w={'750px'}
                            position={'absolute'}
                            borderRadius={'5px'}
                          >
                            <AlertIcon />
                            Редактирование этого раздела доступно только
                            пользователям с правами{' '}
                            <b
                              style={{
                                color: '#004a8f',
                                marginLeft: '5px',
                                textDecoration: 'underline',
                              }}
                            >
                              суперадминистратора
                            </b>{' '}
                            .
                            <CloseButton
                              alignSelf="flex-start"
                              position="relative"
                              right={-7}
                              top={0}
                              onClick={onCloseAlert}
                              size={'sm'}
                            />
                          </Alert>
                        )}
                      </>
                    )}
                  </>
                }

                {puncemType === PuncemType.standard && (
                  <PuncemStandardActions {...standardActionsProps} />
                )}
                {puncemType === PuncemType.custom && (
                  <PuncemCustomActions {...customActionsProps} />
                )}
              </Flex>

              <Tabs
                index={tabData.findIndex((tab) => tab.type === puncemType)}
                isManual
              >
                <TabPanels>
                  {tabData.map((tab) => (
                    <TabPanel key={tab.type} padding={0}>
                      {tab.content}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
