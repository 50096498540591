import { useCallback, useEffect, useMemo, useState } from 'react'
import { subMonths, addMonths, eachMonthOfInterval, format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { normalizeValues } from 'shared/utils'

import moment from 'moment'

import {
  Box,
  Flex,
  Text,
  Button,
  FormLabel,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'

import {
  CreateATCTemplate,
  EditATCTemplate,
  UploadExcelFilesFromATC,
  useUploadFilesATC,
} from 'features/prices'
import { useGetAllACTemplates } from 'entities/prices'

import {
  GetAllACTemplatesResponse,
  UploadATCExcelResponse,
} from 'shared/models'
import {
  DatePickerField,
  FormInputBlock,
  SelectInputForm,
  TextTitle,
} from 'shared/ui'

import { useImportATCDataForm } from './UseImportDataATCForm'

//  misc
import { HiMiniXMark, HiMiniArrowPath } from 'react-icons/hi2'
import { FaUpload } from 'react-icons/fa6'
import { HiCog } from 'react-icons/hi2'

export interface UploadFilesSuccessAction {
  data: UploadATCExcelResponse
}

export interface UploadFilesFailureAction {
  errorMessage: string
  data?: UploadATCExcelResponse
}

export const ImportDataATC: React.FC<{
  isActive?: boolean
  query_date?: string
}> = ({ isActive, query_date }): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [openedTemplates, setOpenedTemplates] = useState<boolean>(false)

  const [fileStatuses, setFileStatuses] = useState<{
    [key: string]: {
      isLoaded?: boolean
      isLoading?: boolean
      isError?: string
      data?: any
    }
  }>({})

  const [listData, setListData] = useState<
    {
      gs_activity_areas_id: string
      period: string
      type: string
      file_id: string
    }[]
  >(null)

  const [fewPeriods, setFewPeriods] = useState<boolean>(false)
  const [openedCollapses, setOpenedCollapses] = useState<{
    [key: string]: boolean
  }>({})

  const { templatesData, data: templates } = useGetAllACTemplates({
    enabled: true,
  })

  const { mutate } = useUploadFilesATC()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
  } = useImportATCDataForm()

  const periodFrom = watch('period_from')
  const oneMonthLater = addMonths(periodFrom, 1)

  useEffect(() => {
    if (!isActive) setOpenedTemplates(false)
  }, [isActive])

  useEffect(() => {
    if (fewPeriods) setValue('period_to', oneMonthLater)
    else setValue('period_to', undefined)
  }, [fewPeriods])

  const removeAllFiles = useCallback(() => {
    setListData(null)
    setFileStatuses({})
    setOpenedCollapses({})
  }, [])

  useEffect(() => {
    removeAllFiles()
  }, [
    watchedFields?.period_from,
    watchedFields?.period_to,
    watchedFields.template,
  ])

  useEffect(() => {
    if (query_date) {
      reset({
        period_from: query_date
          ? normalizeValues(query_date, 'date_month_year')
          : null,
      })
    }
  }, [query_date])

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleSwitchChange = (checked: boolean) => setFewPeriods(checked)

  const handleOpenATCTemplates = useCallback(() => {
    setOpenedTemplates(true)
  }, [onOpen])

  const createATCTemplateProps = {
    isOpen,
    onOpen,
    onClose,
    setOpenedTemplates,
  }

  const handleCreateList = () => {
    const finedTemplate: GetAllACTemplatesResponse = templates.find(
      (template) => template.template_name === watchedFields.template,
    )

    let periods = []
    if (fewPeriods && watchedFields?.period_from && watchedFields?.period_to) {
      periods = eachMonthOfInterval({
        start: new Date(watchedFields.period_from),
        end: new Date(watchedFields.period_to),
      })
    } else periods = [new Date(watchedFields.period_from)]

    const list = periods.flatMap((date) =>
      finedTemplate.data.map((item) => ({
        type: item.template_type,
        period: format(date, 'yyyy-MM-dd'),
        gs_activity_areas_id: item.gs_activity_areas_id,
        file_id: uuidv4(),
      })),
    )

    setListData(list)
  }

  const removeFile = useCallback((index) => {
    setListData((currentFiles) => currentFiles.filter((_, i) => i !== index))
  }, [])

  const updateFileStatus = useCallback((file_id, status) => {
    setFileStatuses((prevStatuses) => ({
      ...prevStatuses,
      [file_id]: { ...prevStatuses[file_id], ...status },
    }))
  }, [])

  const toggleCollapse = useCallback((index) => {
    setOpenedCollapses((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }, [])

  const handleUpload = useCallback(
    ({ force, file_id, report_type, gs_activity_areas_id, date }) => {
      updateFileStatus(file_id, { isLoading: true })

      mutate({
        file_id,
        force,
        report_type,
        gs_activity_areas_id,
        date,
        successAction: ({ data }: UploadFilesSuccessAction) => {
          updateFileStatus(file_id, {
            isLoading: false,
            isError: false,
            isLoaded: true,
            data,
          })
        },
        failureAction: ({ errorMessage, data }: UploadFilesFailureAction) => {
          updateFileStatus(file_id, {
            isLoading: false,
            isError: true,
            errorMessage,
            data,
          })
        },
      })
    },
    [mutate, updateFileStatus],
  )

  const handleUploadAllFiles = useCallback(() => {
    listData.forEach(({ file_id, type, gs_activity_areas_id, period }) => {
      const status = fileStatuses[file_id]
      if (
        !status ||
        (!status.isLoading &&
          !status.isLoaded &&
          !status.isError &&
          !(status?.data?.status === 'warning'))
      ) {
        handleUpload({
          force: false,
          file_id,
          report_type: type,
          gs_activity_areas_id,
          date: period,
        })
      }
    })
  }, [listData, fileStatuses, handleUpload])

  const uploadFilesProps = {
    fileStatuses,
    list: listData,
    openedCollapses,
    removeFile,
    handleUpload,
    toggleCollapse,
  }

  return (
    <Box p="2" position={'relative'}>
      {openedTemplates && (
        <Box
          position={'absolute'}
          zIndex={9}
          bg={'white'}
          borderRadius={'5px'}
          w={'98%'}
          border={'1px solid #dfe0eb'}
        >
          <CreateATCTemplate {...createATCTemplateProps} />
          <EditATCTemplate
            openedTemplates={openedTemplates}
            setOpenedTemplates={setOpenedTemplates}
            templates={templates}
          />
        </Box>
      )}

      <Box mb="15px">
        <Button
          mr="10px"
          position={'relative'}
          size={'xs'}
          bg="teal.400"
          color="white"
          display={'flex'}
          alignItems={'center'}
          onClick={handleOpenATCTemplates}
          _hover={{
            bg: 'teal.500',
          }}
        >
          <Box fontSize={'18px'} mr={'5px'}>
            <HiCog />
          </Box>
          <Text position={'relative'}>Шаблоны</Text>
        </Button>
      </Box>

      <Box p="2" border={'1px solid #dfe0eb'} borderRadius={'5px'}>
        <Flex alignItems={'center'} w={'440px'}>
          <FormInputBlock
            titleWidth={'230px'}
            allowEdit={true}
            edit
            title={'Выберите шаблон списка загрузки'}
          >
            <SelectInputForm
              placeholder="Шаблон"
              getOptionValue={(option) => (option ? option.value : '')}
              name="template"
              data={templatesData || []}
              isClearable={true}
              {...commonInputProps}
            />
          </FormInputBlock>
        </Flex>
        <Box m={'10px'} />
        <Flex>
          <FormInputBlock
            titleWidth={'230px'}
            allowEdit={true}
            title={'Период'}
            edit={true}
          >
            <DatePickerField
              setInitialDate
              format="MM.yyyy"
              maxDate={
                watchedFields?.period_to
                  ? subMonths(
                      new Date(
                        moment(watchedFields.period_to, 'MM.yyyy').toDate(),
                      ),
                      1,
                    )
                  : undefined
              }
              showMonthYearPicker
              name={'period_from'}
              placeholder={'Дата от'}
              type={'text'}
              theme="monthPicker"
              {...commonInputProps}
            />
            {!!watchedFields.period_to && (
              <DatePickerField
                format="MM.yyyy"
                minDate={
                  watchedFields?.period_from
                    ? addMonths(
                        new Date(
                          moment(watchedFields.period_from, 'MM.yyyy').toDate(),
                        ),
                        1,
                      )
                    : undefined
                }
                showMonthYearPicker
                name={'period_to'}
                placeholder={'Дата по'}
                type={'text'}
                theme="monthPicker"
                {...commonInputProps}
              />
            )}
          </FormInputBlock>
        </Flex>
        <Box m={'10px'} />
        <Flex alignItems={'center'}>
          <FormLabel
            fontSize={'14px'}
            color={'gray.500'}
            fontWeight={'500'}
            mb="0"
          >
            Несколько периодов
          </FormLabel>
          <Switch
            size={'sm'}
            id={`1`}
            isChecked={fewPeriods}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleSwitchChange(e.target.checked)
            }
          />
        </Flex>
      </Box>

      <Flex mt={'15px'}>
        <Button
          mr="10px"
          position={'relative'}
          size={'xs'}
          bg="teal.400"
          color="white"
          display={'flex'}
          alignItems={'center'}
          onClick={handleCreateList}
          isDisabled={!watchedFields.template || !watchedFields.period_from}
          _hover={{
            bg: 'teal.500',
          }}
        >
          <Box fontSize={'18px'} mr={'5px'}>
            <HiMiniArrowPath />
          </Box>
          <Text position={'relative'}>Сформировать список</Text>
        </Button>

        <Button
          mr={'10px'}
          position={'relative'}
          size={'xs'}
          display={'flex'}
          onClick={removeAllFiles}
          isDisabled={!listData || listData.length === 0}
        >
          <Box fontSize={'18px'}>
            <HiMiniXMark />
          </Box>
          <Text position={'relative'}>Очистить список</Text>
        </Button>

        <Button
          mr="10px"
          position={'relative'}
          size={'xs'}
          color="white"
          display={'flex'}
          alignItems={'center'}
          onClick={handleUploadAllFiles}
          colorScheme="blue"
          isDisabled={
            !listData ||
            listData.length === 0 ||
            listData.length === Object.keys(fileStatuses).length
          }
        >
          <Box fontSize={'12px'} mr={'5px'}>
            <FaUpload />
          </Box>
          <Text position={'relative'}>Загрузить все</Text>
        </Button>
      </Flex>

      <Box>
        {listData && listData.length > 0 && (
          <Box>
            <TextTitle size="small" mt="25px">
              Сформированный список файлов
            </TextTitle>
            <Box mt="15px">
              <Box>
                <Box
                  border={'1px solid #dfe0eb'}
                  borderRadius={'5px'}
                  p={'10px'}
                  mt="10px"
                >
                  <UploadExcelFilesFromATC {...uploadFilesProps} />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
