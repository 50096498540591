import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { useGetPuncemStandardFormulas } from 'entities/priceCategories'
import { Loader } from 'shared/ui'

import { EditStandardFormulas } from './EditStandardFormulas'

interface StandardFormulasListProps {
  puncem_standard_id: string
  puncem_period_id: string
  isEdit: boolean
}

export const StandardFormulasList: React.FC<StandardFormulasListProps> = memo(
  ({ puncem_standard_id, puncem_period_id }): JSX.Element => {
    const { data, isLoading, isFetching } = useGetPuncemStandardFormulas({
      puncem_standard_id,
      puncem_period_id,
      enabled: !!puncem_standard_id && !!puncem_period_id,
    })

    return (
      <Box>
        {isLoading || isFetching ? (
          <Box m="10px" w="100%">
            <Loader size="sm" minHeight="30px" />
          </Box>
        ) : (
          <>
            {!data || (data && data.length === 0) ? (
              <Box p="10px">
                <Box opacity={'0.5'}>Данные отсутствуют</Box>
              </Box>
            ) : (
              <Box p="10px">
                {data &&
                  data.length > 0 &&
                  data.map((item) => {
                    const editStandardFormulasProps = {
                      ...item,
                      puncem_standard_id,
                      puncem_period_id,
                    }

                    return (
                      <EditStandardFormulas
                        key={item?.puncem_sp_id}
                        {...editStandardFormulasProps}
                        puncem_period_id={puncem_period_id}
                      />
                    )
                  })}
              </Box>
            )}
          </>
        )}
      </Box>
    )
  },
)
