import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'

import { useGetAllProducts } from 'entities/priceCategories'
import { ProductsResponse } from 'shared/models'
import { PriceProductsListV2 } from 'entities/priceCategories/allProducts'

export const ProductsListV2: React.FC = (): JSX.Element => {
  const [products, setProducts] = useState<ProductsResponse[]>([])

  const { data, isLoading } = useGetAllProducts({
    enabled: true,
  })

  useEffect(() => {
    if (data) setProducts(data)
  }, [data])

  return (
    <Box p="2">
      <Box mt="10px" p="2" border={'1px solid #dfe0eb'} borderRadius={'5px'}>
        <PriceProductsListV2 data={products} loading={isLoading} />
      </Box>
    </Box>
  )
}
