import { useQuery } from 'react-query'

import { ExtendedAxiosError, TariffTypesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface TariffTypesData {
  value: string
  label: string
}

interface UseGetTariffTypesResult {
  data: TariffTypesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
  tariffTypesData: TariffTypesData[] | undefined
}

interface UseGetTariffTypes {
  enabled: boolean
  successAction?: () => void
  errorAction?: () => void
}

export function useGetTariffTypesService({
  enabled,
  successAction,
  errorAction,
}: UseGetTariffTypes): UseGetTariffTypesResult {
  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    TariffTypesResponse[]
  >({
    queryKey: [queryKeys.prices.tariffTypes],
    queryFn: () =>
      PriceCategoriesService.getTariffTypes().then((response) => response.data),
    enabled,
    onError: (error: ExtendedAxiosError) => {
      if (errorAction) errorAction()
      toasts.error({
        title: 'Произошла ошибка',
        description:
          error?.response?.data?.detail || 'Данные не были загружены',
      })
    },
    onSuccess: () => {
      if (successAction) successAction()
    },
  })

  const tariffTypesData = data?.map((tariff) => ({
    value: tariff.tariff_type_id,
    label: tariff.tariff_type_name,
  }))

  return {
    data: data,
    isError,
    isFetching,
    isLoading,
    refetch,
    tariffTypesData,
  }
}
