import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError, UpdateCustomPuncemRequest } from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateCustomContractMutation extends UpdateCustomPuncemRequest {
  successAction?: () => void
  errorAction?: () => void
}

export function useUpdateCustomContract(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateCustomContractMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ puncem_user_id, puncem_name }: UpdateCustomContractMutation) =>
      PuncemService.updateCustomContact({
        puncem_user_id,
        puncem_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateCustomContractMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные успешно сохранены',
            description: `Данные контракта успешно сохранены`,
          })
        }
        queryClient.invalidateQueries([queryKeys.puncem.customContracts])
      },
      onError: (
        error: ExtendedAxiosError,
        variables: UpdateCustomContractMutation,
      ) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Данные контракта не были сохранены',
        })

        if (variables?.errorAction) variables?.errorAction()
      },
    },
  )
  return { mutate, isLoading }
}
