import { Resolver, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface EditStandardPeriodFormData {
  date_from?: string
  date_to?: string
}

const SCHEMA_VALIDATION = yup.object().shape({
  // date_from: yup.string().required('Дата обязательное поле'),
})

export const useEditStandardPeriodForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    getValues,
    setError,
  } = useForm<EditStandardPeriodFormData>({
    resolver: yupResolver(
      SCHEMA_VALIDATION,
    ) as Resolver<EditStandardPeriodFormData>,
    mode: 'onChange',
    defaultValues: {
      date_from: null,
      date_to: null,
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    getValues,
    watch,
    setError,
  }
}

// #08a75c
// #0852a7
// #ffffff
// #a70858
// #f371bd
