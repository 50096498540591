import React, { memo, useEffect, useMemo, useState } from 'react'
import { Cascader } from 'rsuite'
import { Box, Flex } from '@chakra-ui/react'

import { lightenColor } from './utils/utils'

interface CascadePickerProps {
  isEdit: boolean
  value: string
  handleSetValues: ({
    value,
    selectedNames,
  }: {
    value: string
    selectedNames: string[]
  }) => void
  selectedNames: string[]
  data: any[]
}

interface CustomToggleProps {
  onClick?: () => void
  selectedNames: string[]
  isEdit: boolean
}
export const Element: React.FC<any> = ({
  children,
  color,
  isFirst = false,
  isLast = false,
}): JSX.Element => {
  return (
    <Box
      fontSize={'14px'}
      position="relative"
      w="fit-content"
      bg={color}
      p="3px 15px"
      mr={isLast ? '0' : '-10px'}
      clipPath={
        isFirst
          ? 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%)'
          : 'polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)'
      }
    >
      {children}
    </Box>
  )
}

export const CustomToggle: React.FC<CustomToggleProps> = ({
  onClick,
  selectedNames,
  isEdit,
}): JSX.Element => {
  return (
    <Box cursor={isEdit ? 'pointer' : 'default'} onClick={onClick}>
      <Flex align={'center'}>
        {selectedNames?.length > 0 ? (
          <>
            {selectedNames.map((el, index) => {
              const color = lightenColor('#58b57b', index * 7)
              return (
                <Box key={index}>
                  <Element
                    color={color}
                    isFirst={index === 0}
                    isLast={index === selectedNames.length - 1}
                  >
                    {el}
                  </Element>
                </Box>
              )
            })}
          </>
        ) : (
          <Element color={'#e3e3e3'} isFirst={true}>
            {'Выберете элемент'}
          </Element>
        )}
      </Flex>
    </Box>
  )
}

export const CascadePicker: React.FC<CascadePickerProps> = ({
  selectedNames,
  handleSetValues,
  data,
  value,
  isEdit,
}): JSX.Element => {
  const handleSelectValue = ({ newValue, newNames }: any) => {
    handleSetValues({
      value: newValue,
      selectedNames: newNames,
    })
  }

  const handleSelect = (node, selectedPaths) => {
    if (selectedPaths) {
      const names = selectedPaths.map((path) => path.label)
      handleSelectValue({
        newNames: names,
        newValue: names[names?.length - 1],
      })
    }
  }

  if (!selectedNames || !data || !data?.length) return null

  return (
    <Box
      position={'relative'}
      border={isEdit ? '1px solid #d4d4d4' : '1px solid transparent'}
      boxShadow={isEdit ? '0 0 0 1px #d4d4d4' : 'none'}
      p="3px 5px"
      pr="15px"
      borderRadius={'5px'}
      cursor={isEdit ? 'pointer' : 'default'}
      _hover={{
        border: '1px solid',
        borderColor: isEdit ? '#3182ce' : 'transparent',
        boxShadow: isEdit ? '0 0 0 1px #3182ce' : '',
      }}
    >
      <Cascader
        disabled={!isEdit}
        // parentSelectable
        value={value}
        onSelect={(node, selectedPaths) =>
          isEdit ? handleSelect(node, selectedPaths) : null
        }
        data={data || []}
        style={{ width: 'fit-content' }}
        searchable={false}
        toggleAs={() => (
          <CustomToggle selectedNames={selectedNames} isEdit={isEdit} />
        )}
        popupClassName={'custom-dropdown'}
      />
    </Box>
  )
}
