import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { addMonths, subMonths } from 'date-fns'
import { debounce } from 'lodash'
import { Box, Card, Collapse, Flex } from '@chakra-ui/react'

import { convertDateForAPI } from 'shared/utils'
import {
  DatePickerField,
  FormInputBlock,
  SubmitCancelButtons,
  TextTitle,
} from 'shared/ui'

import { useCreateStandardPeriodForm } from './CreateStandardPeriodForm'
import { useCreateStandardPeriod } from '../models/services/createStandardPeriodServie'

interface CreateStandardPeriodProps {
  isOpenCreatePeriod: boolean
  setIsOpenCreatePeriod: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateStandardPeriod: React.FC<CreateStandardPeriodProps> = ({
  isOpenCreatePeriod,
  setIsOpenCreatePeriod,
}): JSX.Element => {
  const { mutate: createStandardPeriod, isLoading } = useCreateStandardPeriod()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    watchedFields,
    watch,
    setValue,
    getValues,
    isDirty,
    setError,
    isValid,
  } = useCreateStandardPeriodForm()

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleResetForm = useCallback(() => {
    reset()
  }, [reset])

  const debouncedResetForm = useMemo(
    () => debounce(handleResetForm, 500),
    [handleResetForm],
  )

  const handleClose = useCallback(() => {
    setIsOpenCreatePeriod(false)
    debouncedResetForm()
  }, [debouncedResetForm, setIsOpenCreatePeriod])

  const onSubmit = useCallback(({ date_from, date_to }) => {
    createStandardPeriod({
      date_from: convertDateForAPI(date_from),
      date_to: convertDateForAPI(date_to),
      successAction: () => {
        handleClose()
      },
    })
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex w="100%">
        <Collapse
          in={isOpenCreatePeriod}
          style={{
            overflow: 'inherit',
            width: '100%',
          }}
        >
          <Card
            position={'relative'}
            bg="#fdfdfd"
            boxShadow={'none'}
            variant={'outline'}
          >
            <Box p="10px">
              <TextTitle size="small">Добавление нового периода</TextTitle>
            </Box>
            <hr style={{ margin: 0 }} />
            <Box p="10px">
              <Card
                bg="#00ba7912"
                p="5px"
                boxShadow={'none'}
                variant={'outline'}
                w={'100%'}
                border={'1px solid  #00ba79'}
              >
                <Flex w={'100%'} pl="5px">
                  <Box w="200px">
                    <FormInputBlock
                      valueWidth={'100px'}
                      titleWidth={'60px'}
                      allowEdit={true}
                      title={'Дата с'}
                      edit={true}
                      value={''}
                    >
                      <DatePickerField
                        isRequired={false}
                        format="MM.yyyy"
                        maxDate={
                          watchedFields?.date_to
                            ? subMonths(
                                new Date(
                                  moment(
                                    watchedFields.date_to,
                                    'MM.yyyy',
                                  ).toDate(),
                                ),
                                1,
                              )
                            : undefined
                        }
                        showMonthYearPicker
                        name={'date_from'}
                        placeholder={'Дата от'}
                        type={'text'}
                        theme="monthPicker"
                        {...commonInputProps}
                      />
                    </FormInputBlock>
                  </Box>
                  <Box>
                    <FormInputBlock
                      titleWidth={'60px'}
                      allowEdit={true}
                      title={'Дата по'}
                      edit={true}
                      value={''}
                    >
                      <DatePickerField
                        isRequired={false}
                        format="MM.yyyy"
                        minDate={
                          watchedFields?.date_from
                            ? addMonths(
                                new Date(
                                  moment(
                                    watchedFields.date_from,
                                    'MM.yyyy',
                                  ).toDate(),
                                ),
                                1,
                              )
                            : undefined
                        }
                        showMonthYearPicker
                        name={'date_to'}
                        placeholder={'Дата до'}
                        type={'text'}
                        theme="monthPicker"
                        {...commonInputProps}
                      />
                    </FormInputBlock>
                  </Box>
                </Flex>
              </Card>
              {isDirty && (
                <Flex alignItems={'center'} mt="10px">
                  <Box mr="10px">
                    <SubmitCancelButtons
                      isDisabled={!isValid}
                      isDirty={isDirty}
                      isUpdating={isLoading}
                      handleCancel={() => {
                        setIsOpenCreatePeriod(false)
                      }}
                    />
                  </Box>
                </Flex>
              )}
            </Box>
          </Card>
        </Collapse>
      </Flex>
    </form>
  )
}
