import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import {
  ExtendedAxiosError,
  UpdateStandardPuncemPeriodsRequest,
} from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateStandardPeriodMutation
  extends UpdateStandardPuncemPeriodsRequest {
  successAction?: () => void
  errorAction?: () => void
}

export function useUpdateStandardPeriod(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateStandardPeriodMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ date_to, date_from, puncem_period_id }: UpdateStandardPeriodMutation) =>
      PuncemService.updateStandardPeriod({
        date_to,
        date_from,
        puncem_period_id,
      }),
    {
      onSuccess: async (
        data: AxiosResponse<undefined>,
        variables: UpdateStandardPeriodMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные успешно сохранены',
            description: `Данные контракта успешно сохранены`,
          })
        }
        await queryClient.invalidateQueries([
          queryKeys.puncem.standardContractsPeriods,
        ])
        await queryClient.invalidateQueries([
          queryKeys.puncem.standardContracts,
        ])
      },
      onError: (
        error: ExtendedAxiosError,
        variables: UpdateStandardPeriodMutation,
      ) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Данные контракта не были сохранены',
        })

        if (variables?.errorAction) variables?.errorAction()
      },
    },
  )
  return { mutate, isLoading }
}
