import React from 'react'
import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'
import { useDeleteStandardFormula } from '../models/services/deleteStandardFormulaService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeleteStandardFormulasProps {
  puncem_standard_id: string
  puncem_period_id: string
  puncem_sp_id: string
  puncem_np_id: string
  name: string
  isEdit: boolean
}

export const DeleteStandardFormulas: React.FC<DeleteStandardFormulasProps> = ({
  puncem_sp_id,
  puncem_np_id,
  name,
  puncem_standard_id,
  puncem_period_id,
  isEdit,
}): JSX.Element => {
  const { mutate: deleteStandardContract, isLoading } =
    useDeleteStandardFormula()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCloseModal = () => onClose()

  const handleDeleteFormula = () => {
    deleteStandardContract({
      puncem_standard_id,
      puncem_period_id,
      puncem_sp_id,
      puncem_np_id,
      successAction: () => {
        onClose()
      },
    })
  }

  return (
    <>
      <Button
        isLoading={false}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
        ml={isEdit ? '10px' : 0}
      >
        Удалить формулу расчета
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление формулы расчета"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'35px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Следующая формула будет удалена <b>{name}</b>
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить формулу расчета {name}?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="15px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose} size="sm">
            Отмена
          </Button>
          <Button
            size="sm"
            fontWeight={'500'}
            onClick={handleDeleteFormula}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </>
  )
}
