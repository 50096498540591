import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Card,
  Text,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'

import { useGetProductsThreeService } from 'entities/prices'
import { transformTree } from 'shared/ui/CascadePlanner/utils/utils'
import { FormulaJson } from 'shared/models'
import {
  CascadePicker,
  CascadePlanner,
  FormInputBlock,
  FormInputControl,
} from 'shared/ui'

import { useCreateCustomFormulasForm } from './CreateCustomFormulaForm'
import { useCreateCustomFormulas } from '../models/services/createCustomFormulas'

// misc
import { HiMiniPlusCircle, HiXMark } from 'react-icons/hi2'
import { MdOutlineSave } from 'react-icons/md'

interface CreateCustomFormulaProps {
  puncem_user_id: string
}

export const CreateCustomFormula: React.FC<CreateCustomFormulaProps> = memo(
  ({ puncem_user_id }): JSX.Element => {
    const [cascadeElements, setCascadeElements] = useState<{
      selectedNames: string[]
      value: string
    }>({
      selectedNames: [],
      value: '',
    })

    const [formulaPlannerElements, setFormulaPlannerElements] = useState<
      FormulaJson[]
    >([])

    const { isOpen, onToggle, onClose } = useDisclosure()

    const { data: threeData = [] } = useGetProductsThreeService({
      enabled: true,
    })

    const { mutate: createCustomFormulas, isLoading: isLoadingCreate } =
      useCreateCustomFormulas()

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
      trigger,
      getValues,
    } = useCreateCustomFormulasForm()

    const ref = React.useRef<HTMLDivElement>(null)

    const transformedThreeData = useMemo(
      () => transformTree(threeData || []),
      [threeData],
    )

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const resetForm = () => {
      reset()
      setFormulaPlannerElements([])
      setCascadeElements({
        selectedNames: [],
        value: '',
      })
    }

    const onSubmit = () => {
      const formData = getValues()

      createCustomFormulas({
        default_product: formData?.default_product,
        formula_json: formData?.formula_json as FormulaJson[],
        name: formData?.name,
        puncem_user_id,
        successAction: () => {
          resetForm()
          onClose()
        },
      })
    }

    const productsThreeData = useMemo(() => {
      return transformTree(
        threeData?.filter((el) => el?.structure === 'products'),
      )
    }, [threeData])

    const handleSetValues = useCallback(
      ({ selectedNames, value }) => {
        setCascadeElements({ ...cascadeElements, selectedNames, value })
        setValue('default_product', selectedNames, { shouldDirty: true })
        trigger('default_product')
      },
      [setValue, trigger],
    )

    useEffect(() => {
      setValue('formula_json', formulaPlannerElements, { shouldDirty: true })
      trigger('formula_json')
    }, [formulaPlannerElements])

    const formulaPlannerProps = {
      elements: formulaPlannerElements,
      setElements: setFormulaPlannerElements,
      isPlannerEdit: true,
    }

    const cascadePickerProps = {
      handleSetValues,
      selectedNames: cascadeElements?.selectedNames,
      value: cascadeElements?.value,
      isEdit: true,
    }

    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onToggle()
          }}
          size={'xl'}
          scrollBehavior={'inside'}
        >
          <ModalOverlay />
          <ModalContent
            ref={ref}
            sx={{
              width: '1200px',
              maxWidth: 'none',
              height: '500px',
            }}
          >
            <ModalHeader borderBottom={'1px solid #dfe0eb'}>
              <Box fontSize={'18px'}>Добавление фомулы расчета</Box>
            </ModalHeader>

            <ModalCloseButton
              onClick={() => {
                onToggle()
              }}
            />
            <ModalBody>
              <Card
                p="10px"
                boxShadow={'none'}
                variant={'outline'}
                position={'relative'}
                bg={'#eef1fc45'}
                _hover={{
                  border: '1px solid #11b8a2',
                }}
              >
                <>
                  <Flex mb="5px">
                    <Box w="500px">
                      <FormInputBlock
                        titleWidth={'130px'}
                        allowEdit={true}
                        edit={true}
                        title={'Наименование:'}
                        value={watchedFields?.name}
                      >
                        <FormInputControl
                          isRequired
                          name="name"
                          placeholder="Наименование"
                          type="text"
                          {...commonInputProps}
                        />
                      </FormInputBlock>
                    </Box>

                    <Flex alignItems={'center'} ml="25px">
                      <Text
                        color="#718096"
                        fontSize={'14px'}
                        fontWeight={'500'}
                        mr="15px"
                      >
                        Товар по умолчанию:
                      </Text>
                      <CascadePicker
                        {...cascadePickerProps}
                        data={productsThreeData}
                      />
                    </Flex>
                  </Flex>
                  <Box>
                    <Text color="#718096" fontSize={'14px'} fontWeight={'500'}>
                      Формула расчета:
                    </Text>
                    <Box background="white">
                      <CascadePlanner
                        {...formulaPlannerProps}
                        transformedData={transformedThreeData}
                      />
                    </Box>
                  </Box>
                </>
                <Flex
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  mt="10px"
                >
                  <Box mr="10px">
                    <Flex justifyContent="flex-start">
                      {isDirty && (
                        <>
                          <Button
                            size="xs"
                            type="submit"
                            isLoading={isLoadingCreate}
                            isDisabled={!isValid}
                            bg="teal.400"
                            color="white"
                            display="flex"
                            alignItems="center"
                            _hover={{
                              bg: 'teal.500',
                            }}
                            onClick={onSubmit}
                          >
                            Сохранить
                            <Box fontSize={'18px'} ml={'5px'}>
                              <MdOutlineSave />
                            </Box>
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Box>
                </Flex>
              </Card>
            </ModalBody>

            <ModalFooter borderTop={'1px solid #dfe0eb'}>
              <Button
                variant="ghost"
                size={'xs'}
                onClick={() => {
                  onToggle()
                  resetForm()
                }}
              >
                Закрыть
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Button
          position={'relative'}
          size={'xs'}
          bg="blue.700"
          color="white"
          display={'flex'}
          alignItems={'center'}
          onClick={onToggle}
          _hover={{
            bg: 'blue.600',
          }}
          ml="50px"
        >
          <Box fontSize={'18px'} mr={'5px'}>
            <HiMiniPlusCircle />
          </Box>
          <Text position={'relative'}>Добавить формулу</Text>
        </Button>
      </>
    )
  },
)
