import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { addMonths, subMonths } from 'date-fns'
import { Box, Card, Flex } from '@chakra-ui/react'

import { Access } from 'app/providers'
import {
  GetStandardPuncemPeriodsResponse,
  UpdateStandardPuncemPeriodsRequest,
} from 'shared/models'
import {
  convertDateForAPI,
  formatDateWithNominativeMonth,
  normalizeValues,
} from 'shared/utils'
import {
  DatePickerField,
  EditButton,
  FormInputBlock,
  SubmitCancelButtons,
} from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'
import { useEditStandardPeriodForm } from './EditStandartPeriodForm'
import { DeleteStandardPeriod } from '../../deleteStandardPeriod'
import { useUpdateStandardPeriod } from '../models/services/useUpdateStandardPeriod'
import { ROLES } from 'shared/constants'

interface EditStandardPeriodProps extends GetStandardPuncemPeriodsResponse {
  containerRef: React.RefObject<HTMLDivElement>
}

export const EditStandardPeriod: React.FC<EditStandardPeriodProps> = memo(
  ({ puncem_period_id, date_from, date_to, containerRef }): JSX.Element => {
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
    const [hoveredCardIndex, setHoveredCardIndex] = useState<boolean>(null)
    const [initialValue, setInitialValue] = useState<{
      date_from: string
      date_to: string
    }>({
      date_from,
      date_to,
    })

    const { mutate: updateStandardPeriod, isLoading } =
      useUpdateStandardPeriod()

    const {
      register,
      handleSubmit,
      errors,
      reset,
      control,
      watchedFields,
      watch,
      setValue,
      getValues,
      isDirty,
      setError,
      isValid,
    } = useEditStandardPeriodForm()

    useEffect(() => {
      if (date_from || date_to) {
        reset({
          date_from: date_from
            ? normalizeValues(date_from, 'date_month_year')
            : null,
          date_to: date_to ? normalizeValues(date_to, 'date_month_year') : null,
        })
      }
    }, [date_from, date_to])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
      }),
      [register, errors, watchedFields, control],
    )

    const handleEditClick = useCallback(() => {
      reset()
      setIsEdit(!isEdit)
    }, [isEdit, reset])

    const deleteStandardPeriodProps = {
      isPopoverOpen,
      setIsPopoverOpen,
      hoveredCardIndex,
      setHoveredCardIndex,
      containerRef,
      puncem_period_id,
    }

    const onSubmit = useCallback(
      (submitProps: UpdateStandardPuncemPeriodsRequest) => {
        updateStandardPeriod({
          puncem_period_id,
          date_from: submitProps?.date_from
            ? convertDateForAPI(submitProps.date_from)
            : null,
          date_to: submitProps?.date_to
            ? convertDateForAPI(submitProps.date_to)
            : null,
          successAction: () => {
            setIsEdit(false)
            setInitialValue({
              date_from: submitProps.date_from,
              date_to: submitProps.date_to,
            })
          },
        })
      },
      [watchedFields, puncem_period_id],
    )

    const handleResetForm = () => reset()

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          m="3px 0"
          // bg={
          //   hoveredCardIndex || isPopoverOpen
          //     ? 'red.50'
          //     : isEdit
          //     ? '#f8fde667'
          //     : '#fdfdfd'
          // }
          bg={
            hoveredCardIndex || isPopoverOpen
              ? 'red.50'
              : isEdit
              ? '#eef1fc45'
              : '#fdfdfd'
          }
          p="5px"
          boxShadow={'none'}
          variant={'outline'}
          border={isPopoverOpen && '1px solid red'}
          _hover={{
            border: hoveredCardIndex ? '1px solid red' : '1px solid #11b8a2',
          }}
        >
          <Access roles={[ROLES.SUPERADMIN]}>
            <Flex
              justifyContent="flex-end"
              position={'absolute'}
              right={'10px'}
              top={'8px'}
              zIndex={1}
              w="fit-content"
            >
              <EditButton
                handleEditClick={handleEditClick}
                isEdit={isEdit}
                size={'xs'}
              />
            </Flex>
          </Access>

          <Flex w={'100%'} pl="5px">
            <Box w="200px">
              <FormInputBlock
                valueWidth={'120px'}
                titleWidth={'60px'}
                allowEdit={true}
                title={'Дата с'}
                edit={isEdit}
                value={
                  initialValue?.date_from
                    ? formatDateWithNominativeMonth(
                        new Date(initialValue?.date_from),
                      )
                    : ''
                }
              >
                <DatePickerField
                  isRequired={false}
                  format="MM.yyyy"
                  maxDate={
                    watchedFields?.date_to
                      ? subMonths(
                          new Date(
                            moment(watchedFields.date_to, 'MM.yyyy').toDate(),
                          ),
                          1,
                        )
                      : undefined
                  }
                  showMonthYearPicker
                  name={'date_from'}
                  placeholder={'Дата от'}
                  type={'text'}
                  theme="monthPicker"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            <Box>
              <FormInputBlock
                valueWidth={'200px'}
                titleWidth={'60px'}
                allowEdit={true}
                title={'Дата по'}
                edit={isEdit}
                value={
                  initialValue?.date_to
                    ? formatDateWithNominativeMonth(
                        new Date(initialValue?.date_to),
                      )
                    : 'Текущий момент'
                }
              >
                <DatePickerField
                  isRequired={false}
                  format="MM.yyyy"
                  minDate={
                    watchedFields?.date_from
                      ? addMonths(
                          new Date(
                            moment(watchedFields.date_from, 'MM.yyyy').toDate(),
                          ),
                          1,
                        )
                      : undefined
                  }
                  showMonthYearPicker
                  name={'date_to'}
                  placeholder={'Дата до'}
                  type={'text'}
                  theme="monthPicker"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>

            {isEdit && (
              <Flex alignItems={'flex-end'} pb={'7px'} ml="5px">
                <DeleteStandardPeriod {...deleteStandardPeriodProps} />
              </Flex>
            )}
          </Flex>

          {isDirty && (
            <Flex alignItems={'center'} mt="10px">
              <Box mr="10px">
                <SubmitCancelButtons
                  isDisabled={!isValid}
                  isDirty={isDirty}
                  isUpdating={isLoading}
                  handleCancel={handleResetForm}
                />
              </Box>
            </Flex>
          )}
        </Card>
      </form>
    )
  },
)
