import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Card, Collapse, Flex } from '@chakra-ui/react'

import { Access, useAccess } from 'app/providers'

import {
  CreateCustomFormula,
  CustomFormulasList,
} from 'features/priceCategories'
import { useGetTypesContracts } from 'entities/counteragentsContracts'

import { useGetPuncemStandardContractPeriods } from 'entities/priceCategories'

import {
  GetCustomPuncemResponse,
  UpdateStandardPuncemRequest,
} from 'shared/models'
import { accessDict, modulesDict } from 'shared/dictionary'

import {
  CardBox,
  EditButton,
  FormInputBlock,
  FormInputControl,
  SelectInputForm,
  TextTitle,
} from 'shared/ui'

import { useEditCustomContractForm } from './EditCustomContractForm'
import { useUpdateCustomContract } from '../models/services/useUpdateCustomContract'
// import { CreateStandardFormula } from '../../createStandardFormula'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiXMark } from 'react-icons/hi2'

export interface EditCustomContractProps extends GetCustomPuncemResponse {
  deleteSlot: React.ReactNode
}

export const EditCustomContract: React.FC<EditCustomContractProps> = memo(
  ({ puncem_user_id, puncem_name, deleteSlot }): JSX.Element => {
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const [initialState, setInitialState] = useState<any>({
      puncem_name,
    })

    useEffect(() => {
      setInitialState({
        puncem_name,
      })
    }, [puncem_name])

    const { hasAccess } = useAccess()

    const isUserAbleToEdit: boolean = hasAccess({
      permissions: [accessDict.update_read],
      module: modulesDict.reference_books,
    })

    const { mutate: updateCustomContract, isLoading } =
      useUpdateCustomContract()

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
      getValues,
    } = useEditCustomContractForm()

    useEffect(() => {
      reset(initialState)
    }, [initialState])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const onSubmit = () => {
      const formData = getValues()

      updateCustomContract({
        puncem_name: formData?.puncem_name,
        puncem_user_id,
        successAction: () => {
          setInitialState({
            ...initialState,
            ...formData,
          })
        },
      })
    }

    const handleResetForm = () => reset()

    const handleEditClick = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    return (
      <form>
        <CardBox
          p="10px"
          _hover={{
            bg: isEdit ? '#f8fde61d' : '#eef1fc68',
            border: '1px solid #11b8a284',
            shadow: 'md',
          }}
          transition={'all 0.2s ease-in-out'}
        >
          <Card
            bg={isEdit ? '#0083fd13' : '#fdfdfd'}
            p="10px"
            boxShadow={'none'}
            variant={'outline'}
            position={'relative'}
            _groupHover={{ bg: '#eef1fc45' }}
            transition={'all 0.2s ease-in-out'}
          >
            <Flex
              w="auto"
              justifyContent="flex-end"
              position={'absolute'}
              right={'20px'}
              top={'10px'}
              zIndex={1}
            >
              <EditButton
                handleEditClick={handleEditClick}
                isEdit={isEdit}
                size={'xs'}
              />
            </Flex>
            <Box w="500px">
              <FormInputBlock
                titleWidth={'120px'}
                allowEdit={true}
                edit={isEdit}
                value={puncem_name}
                title={'Наименование:'}
              >
                <FormInputControl
                  name="puncem_name"
                  placeholder="Наименование"
                  type="text"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            <hr style={{ margin: '8px 0' }} />
            <Collapse in={isEdit} animateOpacity>
              <Card boxShadow={'none'} variant={'outline'}>
                <Box alignItems={'center'}>
                  <Flex p="10px">
                    <TextTitle size="small">Формулы расчета</TextTitle>
                    <Access
                      permissions={[accessDict.update_read]}
                      module={modulesDict.reference_books}
                    >
                      <CreateCustomFormula puncem_user_id={puncem_user_id} />
                    </Access>
                  </Flex>
                  <hr style={{ margin: 0 }} />
                </Box>
                <CustomFormulasList
                  puncem_user_id={puncem_user_id}
                  isEdit={isEdit}
                />
              </Card>
            </Collapse>
            <Access
              permissions={[accessDict.update_read]}
              module={modulesDict.reference_books}
            >
              <Flex alignItems={'center'} mt="15px">
                {isEdit && isDirty && (
                  <>
                    <Button
                      size="xs"
                      type="submit"
                      isLoading={isLoading}
                      isDisabled={!isValid}
                      bg="teal.400"
                      color="white"
                      display="flex"
                      alignItems="center"
                      _hover={{
                        bg: 'teal.500',
                      }}
                      onClick={() => onSubmit()}
                    >
                      Сохранить карточку
                      <Box fontSize={'18px'} ml={'5px'}>
                        <MdOutlineSave />
                      </Box>
                    </Button>
                    <Button
                      ml="10px"
                      size="xs"
                      onClick={() => {
                        handleEditClick()
                        handleResetForm()
                      }}
                    >
                      Отмена
                      <Box fontSize={'18px'} ml={'5px'}>
                        <HiXMark />
                      </Box>
                    </Button>
                  </>
                )}
                {isEdit && deleteSlot}
              </Flex>
            </Access>
          </Card>
        </CardBox>
      </form>
    )
  },
)
