import { memo, useEffect, useMemo, useState } from 'react'
import { Box, Collapse } from '@chakra-ui/react'

import {
  CreateStandardContract,
  DeleteStandardContract,
  EditStandardContract,
} from 'features/priceCategories'
import { useGetPuncemStandardContracts } from 'entities/priceCategories'
import { Loader } from 'shared/ui'
import { GetStandardPuncemResponse } from 'shared/models'

import { PuncemStandardListFilters } from './PuncemStandardListFilters'

interface TypesData {
  value: string
  label: string
}

interface PuncemStandardListProps {
  isCreateStandardOpen: boolean
  onCreateStandardToggle: () => void
  isOpenFilters: boolean
  onToggleFilters: () => void
  typesContractsData: TypesData[]
  typesConnectionData: TypesData[]
  tariffTypesData: TypesData[]
  tariffSubTypesData: TypesData[]
  priceCategoriesData: TypesData[]
  lossesTypesData: TypesData[]
  filters: { [key: string]: string } | null
  setFilters: React.Dispatch<React.SetStateAction<any>>
}

export const PuncemStandardList: React.FC<PuncemStandardListProps> = memo(
  ({
    isOpenFilters,
    isCreateStandardOpen,
    onCreateStandardToggle,
    filters,
    setFilters,
  }): JSX.Element => {
    const [originalList, setOriginalList] = useState<
      GetStandardPuncemResponse[]
    >([])
    const [filteredList, setFilteredList] = useState<
      GetStandardPuncemResponse[]
    >([])

    const { data: puncemStandardList = [], isLoading } =
      useGetPuncemStandardContracts({
        enabled: true,
      })

    useEffect(() => {
      setOriginalList(puncemStandardList)
    }, [puncemStandardList])

    useEffect(() => {
      if (!filters || Object.keys(filters).length === 0) {
        setFilteredList(originalList)
        return
      }

      const filtered = originalList.filter((item) => {
        const matchesTypeContract =
          !filters.type_contract_id ||
          item?.type_contract_id === filters.type_contract_id

        const matchesPriceCategory =
          !filters.price_category_id ||
          item?.price_category_id === filters.price_category_id

        return matchesTypeContract && matchesPriceCategory
      })

      setFilteredList(filtered)
    }, [filters, originalList])

    const filtersProps = useMemo(
      () => ({
        filters,
        setFilters,
      }),
      [filters, setFilters],
    )

    return (
      <Box>
        <Box
          position="relative"
          zIndex="1"
          boxShadow={
            isCreateStandardOpen ? '0px 2px  5px rgba(0, 0, 0, 0.12)' : 'none '
          }
          borderTop={'1px solid #319795'}
          bg="#f7f8fb79"
        >
          <CreateStandardContract
            isOpen={isCreateStandardOpen}
            onToggle={onCreateStandardToggle}
          />
        </Box>
        <Box pt="0">
          <Collapse
            in={isOpenFilters}
            style={{
              overflow: 'inherit',
            }}
          >
            <PuncemStandardListFilters {...filtersProps} />
          </Collapse>

          <Box
            bg="#fdfdfd"
            p="10px"
            position={'relative'}
            h={'82vh'}
            pb="500px"
            overflowY="scroll"
            borderBottom={'1px solid #eeeeee'}
            borderRadius={'6px'}
          >
            {isLoading ? (
              <Box m="150px 0">
                <Loader size="md" />
              </Box>
            ) : (
              <>
                {filteredList.length === 0 ? (
                  <Box m="0">
                    <Box opacity={'0.5'}>Ничего не найдено</Box>
                  </Box>
                ) : (
                  <>
                    {filteredList.map((item, index) => (
                      <Box key={item?.puncem_standard_id} mb="15px">
                        <EditStandardContract
                          {...item}
                          deleteSlot={
                            <DeleteStandardContract
                              puncem_standard_id={item?.puncem_standard_id}
                            />
                          }
                        />
                      </Box>
                    ))}
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    )
  },
)
