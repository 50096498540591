import { useQuery } from 'react-query'

import { GetStandardPuncemPeriodsResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PuncemService } from 'shared/services'
import { formatDateWithNominativeMonth } from 'shared/utils'

interface PuncemStandardContractsData {
  value: string
  label: string
}

interface UseGetPuncemStandardContractsResult {
  data: GetStandardPuncemPeriodsResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  puncemStandardContractsData: PuncemStandardContractsData[] | undefined
}

interface UseGetPuncemStandardContracts {
  enabled: boolean
}

export function useGetPuncemStandardContractPeriods({
  enabled,
}: UseGetPuncemStandardContracts): UseGetPuncemStandardContractsResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GetStandardPuncemPeriodsResponse[]
  >({
    queryKey: [queryKeys.puncem.standardContractsPeriods],
    queryFn: () =>
      PuncemService.getPuncemStandardContractPeriods().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  const puncemStandardContractsData = data?.map((x) => ({
    value: x.puncem_period_id,
    label: `${
      x.date_from
        ? ` От ${formatDateWithNominativeMonth(new Date(x.date_from))}`
        : ''
    } ${x.date_to ? 'до' : 'и'} ${
      x.date_to ? formatDateWithNominativeMonth(new Date(x.date_to)) : 'далее'
    }`,
  }))

  return { data, isError, isFetching, isLoading, puncemStandardContractsData }
}
