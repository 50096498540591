import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError, CreateCustomPuncemRequest } from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface CreateCustomContractMutation extends CreateCustomPuncemRequest {
  successAction?: () => void
  errorAction?: () => void
}

export function useCreateCustomContract(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    CreateCustomContractMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ puncem_name }: CreateCustomContractMutation) =>
      PuncemService.createCustomContact({
        puncem_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: CreateCustomContractMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные успешно обновлены',
            description: `Данные контракта успешно обновлены`,
          })
        }
        queryClient.invalidateQueries([queryKeys.puncem.customContracts])
      },
      onError: (
        error: ExtendedAxiosError,
        variables: CreateCustomContractMutation,
      ) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Данные контракта не были обновлены',
        })

        if (variables?.errorAction) variables?.errorAction()
      },
    },
  )
  return { mutate, isLoading }
}
