import { Center, Flex, Spinner } from '@chakra-ui/react'

type LoaderPropsType = {
  size?: string
  minHeight?: string
}

export const Loader: React.FC<LoaderPropsType> = (props) => {
  const { size = 'md', minHeight = '100px' } = props

  return (
    <Flex justifyContent="center" minHeight={minHeight}>
      <Center>
        <Spinner
          thickness="2px"
          speed="0.35s"
          emptyColor="gray.200"
          color="blue.500"
          size={size}
        />
      </Center>
    </Flex>
  )
}
