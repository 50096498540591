import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import { BaseModal } from 'shared/ui'
import { useDeleteCustomContract } from '../models/services/deleteCustomContractService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeleteCustomContractProps {
  puncem_user_id: string
  puncem_name: string
}

export const DeleteCustomContract: React.FC<DeleteCustomContractProps> = (
  props,
) => {
  const { puncem_user_id, puncem_name } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: deleteCustomContract, isLoading } = useDeleteCustomContract()

  const handleCloseModal = () => onClose()

  const handleCustomContract = () => {
    deleteCustomContract({
      puncem_user_id,
      successAction: () => onClose(),
    })
  }

  return (
    <div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Удалить карточку
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление пользовательской карточки ПУНЦЕМ"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'35px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Карточка будет удалена
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить карточку <b>{puncem_name}</b>?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="15px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose} size="sm">
            Отмена
          </Button>
          <Button
            size="sm"
            fontWeight={'500'}
            onClick={handleCustomContract}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
