type Period = {
  date_from: string
  date_to: string
  id?: string
}

export function checkPeriodsOverlap(
  existingPeriods: Period[],
  newPeriod: Period,
  excludedId?: string,
  setIsOpenNotification?: Function,
): boolean {
  let absorbedPeriods: string[] = []
  let absorbedIds: string[] = []

  for (const period of existingPeriods) {
    if (period.id === excludedId) continue

    const existingFrom = new Date(period.date_from)
    const existingTo = new Date(period.date_to || '9999-12-31')
    const newFrom = new Date(newPeriod.date_from)
    const newTo = new Date(newPeriod.date_to || '9999-12-31')

    if (newFrom <= existingFrom && newTo >= existingTo) {
      absorbedPeriods.push(`с ${period.date_from} по ${period.date_to}`)
      if (period.id) {
        absorbedIds.push(period.id)
      }
    }
  }

  if (absorbedPeriods.length > 0) {
    const message = `Новый период с ${newPeriod.date_from} по ${
      newPeriod.date_to
    } полностью поглощает периоды: ${absorbedPeriods.join(', ')}`

    setIsOpenNotification({
      isOpen: true,
      message,
    })
    return false
  } else {
    setIsOpenNotification({
      isOpen: false,
      message: null,
    })
    return true
  }
}
