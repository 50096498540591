import React, { useCallback, useEffect, useMemo } from 'react'
import { mapValues } from 'lodash'
import { Box, Card, Collapse, Flex, FormLabel, Switch } from '@chakra-ui/react'

import { useGetTypesContracts } from 'entities/counteragentsContracts'

import {
  useGetLossesTypesService,
  useGetPriceCategoriesService,
  useGetTariffSubTypesService,
  useGetTariffTypesService,
  useGetTypesConnectionService,
} from 'entities/prices'

import { CreateStandardPuncemRequest } from 'shared/models'
import {
  CardBox,
  DatePickerField,
  FormInputBlock,
  SelectInputForm,
  SubmitCancelButtons,
  TextTitle,
} from 'shared/ui'

import { useCreateStandardContractForm } from './CreateStandartdContractForm'
import { useCreateStandardContract } from '../models/service/createStandardContractServie'

interface CreateStandardContractProps {
  isOpen: boolean
  onToggle: () => void
}

export const CreateStandardContract: React.FC<CreateStandardContractProps> = ({
  isOpen,
  onToggle,
}): JSX.Element => {
  const { typesContractsData = [] } = useGetTypesContracts({
    enabled: true,
  })

  const { typesConnectionData = [] } = useGetTypesConnectionService({
    enabled: true,
  })

  const { tariffTypesData = [] } = useGetTariffTypesService({
    enabled: true,
  })

  const { tariffSubTypesData = [] } = useGetTariffSubTypesService({
    enabled: true,
  })

  const { priceCategoriesData = [] } = useGetPriceCategoriesService({
    enabled: true,
  })

  const { lossesTypesData } = useGetLossesTypesService({
    enabled: true,
  })

  const { mutate: createStandardContract, isLoading } =
    useCreateStandardContract()
  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
    isValid,
  } = useCreateStandardContractForm()

  const transmissionServicesEnabled = watchedFields?.transmission_service
  const pricesCategoryEnabled = watchedFields?.price_category

  useEffect(() => {
    if (transmissionServicesEnabled === false) {
      setValue('type_connection_id', null, { shouldDirty: true })
      setValue('tariff_type_id', null, { shouldDirty: true })
      setValue('tariff_subtype_id', null, { shouldDirty: true })
    }
  }, [transmissionServicesEnabled, setValue])

  useEffect(() => {
    if (pricesCategoryEnabled === false) {
      setValue('price_category_id', null, { shouldDirty: true })
      setValue('type_loss_id', null, { shouldDirty: true })
    }
  }, [pricesCategoryEnabled, setValue])

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleTransmissionServicesSwitchChange = (checked: boolean) =>
    setValue('transmission_service', checked)

  const handlePricesCategorySwitchChange = (checked: boolean) =>
    setValue('price_category', checked)

  const handleResetForm = useCallback(() => {
    reset()
    onToggle()
  }, [reset, onToggle])

  const onSubmit = useCallback(
    (submitProps: CreateStandardPuncemRequest) => {
      const transformValues = <T extends Record<string, unknown>>(obj: T): T =>
        mapValues(obj, (value) =>
          value === undefined || value === ''
            ? null
            : typeof value === 'object' && value !== null
            ? transformValues(value as Record<string, unknown>)
            : value,
        ) as T

      const transformedProps = transformValues({
        ...submitProps,
      })

      createStandardContract({
        ...transformedProps,
        successAction: () => {
          handleResetForm()
          onToggle()
        },
      })
    },
    [watchedFields],
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isOpen} animateOpacity style={{ overflow: 'inherit' }}>
        <Box p="10px" pb="0">
          <TextTitle size="small">Добавление новой карточки</TextTitle>
        </Box>
        <CardBox p="10px" m="10px">
          <Card
            bg="#eef1fc45"
            p="10px"
            boxShadow={'none'}
            variant={'outline'}
            position={'relative'}
          >
            <Box w="500px">
              <FormInputBlock
                titleWidth={'150px'}
                allowEdit={true}
                title={'Тип договора'}
                edit={true}
                value={''}
              >
                <SelectInputForm
                  placeholder="Выберите тип договора"
                  isRequired
                  data={typesContractsData}
                  isCreatable={false}
                  isClearable={true}
                  getOptionValue={(option) => (option ? option.value : '')}
                  name="type_contract_id"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>

            <hr style={{ margin: '8px 0' }} />

            <Flex alignItems={'center'} mt="5px">
              <FormLabel
                fontSize={'14px'}
                color={'gray.500'}
                fontWeight={'500'}
                mb="0"
                mr="20px"
              >
                Услуги по передаче
              </FormLabel>
              <Switch
                isReadOnly={false}
                isDisabled={false}
                size={'sm'}
                id={`1`}
                isChecked={transmissionServicesEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleTransmissionServicesSwitchChange(e.target.checked)
                }
              />
            </Flex>
            <Flex alignItems={'center'} mt="10px">
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'150px'}
                  allowEdit={true}
                  title={'Тип присоеденения'}
                  edit={true}
                  value={
                    typesConnectionData.find(
                      (typesConnectionData) =>
                        typesConnectionData.value ===
                        watchedFields?.type_connection_id,
                    )?.label
                  }
                >
                  <SelectInputForm
                    isRequired={false}
                    isDisabled={transmissionServicesEnabled === false}
                    isReadOnly={transmissionServicesEnabled === false}
                    placeholder="Выберите тип присоеденения"
                    data={typesConnectionData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="type_connection_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'100px'}
                  allowEdit={true}
                  title={'Тип тарифа'}
                  edit={true}
                  value={''}
                >
                  <SelectInputForm
                    isDisabled={transmissionServicesEnabled === false}
                    isReadOnly={transmissionServicesEnabled === false}
                    placeholder="Выберите тип тарифа"
                    data={tariffTypesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="tariff_type_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'130px'}
                  allowEdit={true}
                  title={'Подтип тарифа'}
                  edit={true}
                  value={''}
                >
                  <SelectInputForm
                    isDisabled={transmissionServicesEnabled === false}
                    isReadOnly={transmissionServicesEnabled === false}
                    placeholder="Выберите подтип тарифа"
                    data={tariffSubTypesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="tariff_subtype_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <hr style={{ margin: '8px 0' }} />

            <Flex alignItems={'center'} mt="5px">
              <FormLabel
                fontSize={'14px'}
                color={'gray.500'}
                fontWeight={'500'}
                mb="0"
                mr="20px"
              >
                Ценовые категории
              </FormLabel>
              <Switch
                isReadOnly={false}
                isDisabled={false}
                size={'sm'}
                id={`2`}
                isChecked={watchedFields?.price_category}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePricesCategorySwitchChange(e.target.checked)
                }
              />
            </Flex>

            <Flex alignItems={'center'} mt="10px">
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'150px'}
                  allowEdit={true}
                  title={'Ценовая категория'}
                  edit={true}
                  value={''}
                >
                  <SelectInputForm
                    isDisabled={pricesCategoryEnabled === false}
                    isReadOnly={pricesCategoryEnabled === false}
                    placeholder="Выберите ценовую категоию"
                    data={priceCategoriesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="price_category_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'100px'}
                  allowEdit={true}
                  title={'Вид потерь'}
                  edit={true}
                  value={''}
                >
                  <SelectInputForm
                    isDisabled={pricesCategoryEnabled === false}
                    isReadOnly={pricesCategoryEnabled === false}
                    placeholder="Выберите вид потерь"
                    data={lossesTypesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="type_loss_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <hr style={{ margin: '8px 0' }} />
            {/* <Box>
            <FormInputBlock
              titleWidth={'150px'}
              allowEdit={true}
              title={'Период действия'}
              edit={isEdit}
            >
              <DatePickerField
                setInitialDate
                format="MM.yyyy"
                showMonthYearPicker
                name={'period'}
                placeholder={'Период'}
                type={'text'}
                theme="monthPicker"
                {...commonInputProps}
              />
            </FormInputBlock>
          </Box> */}
            {isDirty && (
              <Box mt={'15px'}>
                <SubmitCancelButtons
                  isDirty={isDirty}
                  isUpdating={isLoading}
                  handleCancel={handleResetForm}
                  isDisabled={!isValid}
                />
              </Box>
            )}
          </Card>
        </CardBox>
      </Collapse>
    </form>
  )
}
