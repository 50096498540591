import { useQuery } from 'react-query'

import {
  GetStandardPuncemFormulasRequest,
  GetStandardPuncemFormulasResponse,
} from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PuncemService } from 'shared/services'

interface UseGetPuncemStandardFormulasResult {
  data: GetStandardPuncemFormulasResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetPuncemStandardFormulas
  extends GetStandardPuncemFormulasRequest {
  enabled: boolean
}

export function useGetPuncemStandardFormulas({
  enabled,
  puncem_standard_id,
  puncem_period_id,
}: UseGetPuncemStandardFormulas): UseGetPuncemStandardFormulasResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GetStandardPuncemFormulasResponse[]
  >({
    queryKey: [
      queryKeys.puncem.standardContractsFormulas,
      puncem_standard_id,
      puncem_period_id,
    ],
    queryFn: () =>
      PuncemService.getPuncemStandardFormulas({
        puncem_standard_id,
        puncem_period_id,
      }).then((response) => response.data),
    enabled,
    retry: false,
  })

  return { data, isError, isFetching, isLoading }
}
