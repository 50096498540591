import { ProductsThreeResponse } from 'shared/models'

export interface CascadePlannerNode {
  label: string
  value: string
  children?: CascadePlannerNode[]
}

export interface TreeNode {
  label: string
  value: string
  node_id: string
  node_name: string
  structure: string
  children?: TreeNode[]
}

export interface CascadePlannerProps {
  elements: ElementsState[]
  setElements: React.Dispatch<React.SetStateAction<ElementsState[]>>
  isPlannerEdit: boolean
  transformedData: TreeNode[]
}

export enum ElementTypes {
  input,
  builder,
}

export interface ElementsState {
  type: ElementTypes
  value: any
  elementId: string
  editable: boolean
  autoFocus: boolean
  selectedNames: string[]
  multiSelectedValues: string[]
  multi: boolean
}

export interface AddElementMenuProps {
  onAddElement: (ElementTypes) => void
}

export interface BuilderProps extends ElementsState {
  initialValue?: any
  transformedData: TreeNode[]
  handleChangeEditable: (id: string, editable: boolean) => void
  onDelete: () => void
  elementIndex?: string
  handleSetValues: ({
    value,
    selectedNames,
    multi,
    multiSelectedValues,
    elementId,
  }) => void
  isPlannerEdit?: boolean
}

export interface CustomToggleProps {
  onClick?: () => void
  selectedNames: string[]
  multiSelectedValues: string[]
  elementIndex?: string
  isPlannerEdit?: boolean
}

export interface ElementProps {
  children?: React.ReactNode
  color: string
  fontColor: string
  isFirst?: boolean
  isLast?: boolean
}

export interface InputBuilderProps {
  editable: boolean
  elementId: string
  onBlur: (value: string) => void
  handleChangeEditable: (id: string, editable: boolean) => void
  onDelete: () => void
  transformedData: TreeNode[]
  initialValue?: string
  autoFocus?: boolean
  elementIndex?: string
  isPlannerEdit?: boolean
}
