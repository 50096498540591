import { ProductsThreeResponse } from 'shared/models'
import { TreeNode } from '../types/types'

export const lightenColor = (color: string, percent: number): string => {
  let num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

export function transformTree(data: ProductsThreeResponse[]): TreeNode[] {
  const transformedData = data.map((item) => ({
    label: item.node_name,
    value: item.node_name,
    node_id: item.node_id,
    node_name: item.node_name,
    structure: item.structure,
    ...(item.child ? { children: transformTree(item.child) } : {}),
  }))
  return transformedData
}

/**
 * Определяет, является ли цвет тёмным или светлым, и возвращает цвет шрифта.
 * @param color - Цвет в формате HEX (#RRGGBB)
 * @returns Цвет шрифта: 'white' для тёмного фона и 'black' для светлого.
 */
export const getContrastingTextColor = (color: string): 'white' | 'black' => {
  if (!/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
    throw new Error(`Invalid color format: ${color}`)
  }

  const fullColor =
    color.length === 4
      ? `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`
      : color

  const r = parseInt(fullColor.slice(1, 3), 16)
  const g = parseInt(fullColor.slice(3, 5), 16)
  const b = parseInt(fullColor.slice(5, 7), 16)

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

  return luminance < 160 ? 'white' : 'black'
}
