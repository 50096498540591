import React, { memo, useMemo, useEffect, useCallback } from 'react'
import { Box, Button, Card, Flex, Grid, GridItem } from '@chakra-ui/react'
import { FormInputBlock, SelectInputForm } from 'shared/ui'

import { useStandardListFiltersForm } from './PuncemStandardListFiltersForm'
import { useGetTypesContracts } from 'entities/counteragentsContracts'
import { useGetPriceCategoriesService } from 'entities/prices'

// misc
import { LuFilter, LuFilterX } from 'react-icons/lu'
import { HiOutlineEye } from 'react-icons/hi2'

interface PuncemStandardListFiltersProps {
  filters: { [key: string]: string } | null
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
}

export const PuncemStandardListFilters: React.FC<PuncemStandardListFiltersProps> =
  memo(({ setFilters, filters }): JSX.Element => {
    const { typesContractsData = [] } = useGetTypesContracts({
      enabled: true,
    })

    const { priceCategoriesData = [] } = useGetPriceCategoriesService({
      enabled: true,
    })

    const {
      register,
      handleSubmit,
      errors,
      reset,
      control,
      isDirty,
      watchedFields,
    } = useStandardListFiltersForm()

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    useEffect(() => {
      if (!watchedFields.type_contract_id && !watchedFields.price_category_id)
        setFilters(null)
    }, [watchedFields])

    const handleSetFilters = useCallback(() => {
      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          ...watchedFields,
        }
        return newFilters
      })
    }, [setFilters, watchedFields])

    const handleResetFilters = useCallback(() => {
      reset()
      setFilters(null)
    }, [reset, setFilters])

    return (
      <Card
        p="5px"
        boxShadow={'none'}
        variant={'filled'}
        bg="white"
        borderBottom={'1px solid #e2e8f0'}
        borderRadius={'0'}
      >
        <Card variant={'filled'} p="5px">
          <Flex alignItems={'center'} pl="15px">
            <Grid
              w="1000px"
              maxWidth="1000px"
              templateColumns="repeat(2, 1fr)"
              gap={3}
            >
              <GridItem>
                <Flex alignItems={'center'} position={'relative'}>
                  <Box
                    mr="3px"
                    mb="2px"
                    color={filters?.type_contract_id ? 'green' : 'black'}
                  >
                    <LuFilter
                      opacity={filters?.type_contract_id ? 1 : 0.2}
                      fontSize={'12px'}
                    />
                  </Box>

                  <FormInputBlock
                    titleWidth={'100px'}
                    allowEdit={true}
                    title={'Тип договора'}
                    edit={true}
                    value={''}
                    titleColor={filters?.type_contract_id ? 'green' : 'black'}
                  >
                    <SelectInputForm
                      placeholder="Выберите тип товора"
                      data={typesContractsData}
                      isCreatable={false}
                      isClearable={true}
                      getOptionValue={(option) => (option ? option.value : '')}
                      name="type_contract_id"
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex alignItems={'center'} position={'relative'}>
                  <Box
                    mr="3px"
                    mb="2px"
                    color={filters?.price_category_id ? 'green' : 'black'}
                  >
                    <LuFilter
                      opacity={filters?.price_category_id ? 1 : 0.2}
                      fontSize={'12px'}
                    />
                  </Box>
                  <FormInputBlock
                    titleWidth={'150px'}
                    allowEdit={true}
                    title={'Ценовая категория'}
                    edit={true}
                    value={''}
                    titleColor={filters?.price_category_id ? 'green' : 'black'}
                  >
                    <SelectInputForm
                      placeholder="Выберите категорию"
                      data={priceCategoriesData}
                      isCreatable={false}
                      isClearable={true}
                      getOptionValue={(option) => (option ? option.value : '')}
                      name="price_category_id"
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                </Flex>
              </GridItem>
            </Grid>
            <Flex ml="15px">
              <Button
                onClick={handleSetFilters}
                size={'xs'}
                colorScheme="blue"
                isDisabled={
                  !watchedFields?.price_category_id &&
                  !watchedFields?.type_contract_id
                }
              >
                Показать
                <Box fontSize={'18px'} ml={'5px'}>
                  <HiOutlineEye />
                </Box>
              </Button>
              {!!Object.keys(filters || {})?.length && (
                <Button
                  ml="10px"
                  onClick={handleResetFilters}
                  size={'xs'}
                  border="1px solid lightgray"
                >
                  Очистить
                  <Box ml="5px">
                    <LuFilterX fontSize={'16px'} />
                  </Box>
                </Button>
              )}
            </Flex>
          </Flex>
        </Card>
      </Card>
    )
  })
