import { useContext } from 'react'
import { AccessContext } from './AccessProvider'

export const Access: React.FC<{
  permissions?: string[]
  module?: string
  roles?: string[]
  children: React.ReactNode
}> = ({ children, permissions, module, roles }): React.ReactElement => {
  const { roles: userRoles, permissions: userPermissions } =
    useContext(AccessContext)

  const hasRoleAccess = roles
    ? roles.some((role) => userRoles.includes(role))
    : true

  const hasModuleAccess =
    permissions && module
      ? userPermissions.some(
          (p) =>
            p.module === module &&
            permissions.some((access) => p.access.includes(access)),
        )
      : true

  return hasRoleAccess && hasModuleAccess ? <>{children}</> : null
}
