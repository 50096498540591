import React, { memo } from 'react'

import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react'

import { AddElementMenuProps, ElementTypes } from '../types/types'

// misc
import { HiMiniCog, HiMiniPencil, HiMiniPlusCircle } from 'react-icons/hi2'

export const AddElementMenu: React.FC<AddElementMenuProps> = memo(
  ({ onAddElement }): JSX.Element => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          as={Button}
          cursor={'pointer'}
          colorScheme={'blue'}
          bg="blue.700"
          _hover={{ bg: 'blue.600' }}
        >
          <Box fontSize={'15px'} color="white">
            <HiMiniPlusCircle />
          </Box>
        </MenuButton>
        <MenuList>
          <MenuGroup title="Добавить">
            <MenuDivider />
            <MenuItem
              onClick={() => onAddElement(ElementTypes.builder)}
              fontSize={'14px'}
              icon={
                <Box fontSize={'20px'}>
                  <HiMiniCog />
                </Box>
              }
            >
              Элемент
            </MenuItem>
            <MenuItem
              onClick={() => onAddElement(ElementTypes.input)}
              fontSize={'14px'}
              icon={
                <Box fontSize={'20px'}>
                  <HiMiniPencil />
                </Box>
              }
            >
              Строку
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    )
  },
)
