import React, { createContext, useContext, useState } from 'react'
import { LOCAL_STORAGE_ENTITY } from 'shared/constants'
import { localStorageHandler } from 'shared/utils'

interface ColorPaletteContextType {
  palette: string
  setPalette: (palette: string) => void
}

const ColorPaletteContext = createContext<ColorPaletteContextType | undefined>(
  undefined,
)

export const ColorPaletteProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [palette, setPalette] = useState(
    localStorageHandler.getFromLocalStorage<string>(
      LOCAL_STORAGE_ENTITY.COLOR_PALLETE,
      'defaultPalette',
    ) || 'defaultPalette',
  )

  return (
    <ColorPaletteContext.Provider value={{ palette, setPalette }}>
      {children}
    </ColorPaletteContext.Provider>
  )
}

export const useColorPalette = () => {
  const context = useContext(ColorPaletteContext)
  if (!context) {
    throw new Error(
      'useColorPalette must be used within a ColorPaletteProvider',
    )
  }
  return context
}
