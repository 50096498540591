import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import {
  ExtendedAxiosError,
  CreateCustomPuncemFormulasRequest,
} from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface CreateCustomFormulasMutation
  extends CreateCustomPuncemFormulasRequest {
  successAction?: () => void
  errorAction?: () => void
}

export function useCreateCustomFormulas(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    CreateCustomFormulasMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      name,
      default_product,
      formula_json,
      puncem_user_id,
    }: CreateCustomFormulasMutation) =>
      PuncemService.createCustomFormulas({
        name,
        default_product,
        formula_json,
        puncem_user_id,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: CreateCustomFormulasMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Данные успешно сохранены',
            description: `Данные формулы расчета успешно сохранены`,
          })
        }

        queryClient.invalidateQueries([
          queryKeys.puncem.customContractsFormulas,
          variables?.puncem_user_id,
        ])
      },
      onError: (
        error: ExtendedAxiosError,
        variables: CreateCustomFormulasMutation,
      ) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail ||
            'Данные формулы расчета не были сохранены',
        })

        if (variables?.errorAction) variables?.errorAction()
      },
    },
  )
  return { mutate, isLoading }
}
