import React, { useEffect, useState } from 'react'
import { Cascader } from 'rsuite'
import clsx from 'clsx'
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react'

import { CustomToggle } from './CustomToggle'
import { BuilderProps } from '../types/types'

// misc
import {
  HiMiniXCircle,
  HiOutlinePlus,
  HiOutlineSquaresPlus,
} from 'react-icons/hi2'

export const Builder: React.FC<BuilderProps> = ({
  editable,
  elementId,
  handleChangeEditable,
  onDelete,
  elementIndex,
  transformedData,
  value,
  selectedNames,
  multi,
  multiSelectedValues,
  handleSetValues,
  isPlannerEdit,
}): JSX.Element => {
  const [isDeleteHover, setIsDeleteHover] = useState<boolean>(false)

  useEffect(() => {
    if (!multi) handleMultiSelect({ multiValues: [], multiValuesData: [] })
  }, [multi])

  const handleSelectMulti = (newValue: boolean) => {
    handleSetValues({
      multiSelectedValues,
      elementId,
      value,
      selectedNames,
      multi: newValue,
    })
  }

  const handleSelect = (node: any, selectedPaths: any) => {
    if (selectedPaths) {
      handleMultiSelect({ multiValues: [], multiValuesData: [] })
      handleSelectMulti(false)
      const names = selectedPaths.map((path: any) => path.label)

      handleSetValues({
        multiSelectedValues: [],
        elementId,
        value: names[names?.length - 1],
        selectedNames: names,
        multi: false,
      })
    }
  }

  const handleMultiSelect = ({
    multiValues,
    multiValuesData,
  }: {
    multiValues: string[]
    multiValuesData: string[]
  }) => {
    const lastSelectedName = selectedNames[selectedNames.length - 1]

    const updatedSelectedNames = multiValuesData.includes(lastSelectedName)
      ? selectedNames.slice(0, -1)
      : selectedNames

    handleSetValues({
      multiSelectedValues: multiValues,
      elementId,
      value,
      selectedNames: updatedSelectedNames,
      multi,
    })
  }

  const handleClick = () => {
    if (!editable) handleChangeEditable(elementId, true)
  }

  const renderMenu = (node: React.ReactNode, column: any) => {
    const isLastColumn: boolean =
      column.items &&
      column.items.every(
        (item: any) => !item.children || item.children.length === 0,
      )

    return (
      <Box>
        {isLastColumn && (
          <Box
            borderBottom="1px solid #ccc"
            bg={multi ? 'teal.400' : 'blue.700'}
            color={'white'}
          >
            <Menu>
              <MenuButton
                p="5px"
                h={'28px'}
                as={Flex}
                display={'flex'}
                alignItems={'center'}
                onClick={() => handleSelectMulti(!multi)}
                cursor={'pointer'}
              >
                <Flex alignItems={'center'} minW={'250px'}>
                  <Box mr="5px" fontSize={'18px'}>
                    {multi ? <HiOutlinePlus /> : <HiOutlineSquaresPlus />}
                  </Box>
                  {multi
                    ? 'Выбрать один элемент'
                    : 'Выбрать несколько опциональными'}
                </Flex>
              </MenuButton>

              {multi && (
                <MenuOptionGroup
                  type="checkbox"
                  value={multiSelectedValues}
                  onChange={(values) =>
                    handleMultiSelect({
                      multiValues: values as string[],
                      multiValuesData: column?.items?.map(
                        (e) => e.label,
                      ) as string[],
                    })
                  }
                  h={'26px'}
                >
                  {column?.items?.map((item) => (
                    <MenuItemOption
                      h={'26px'}
                      borderTop={'1px solid #e3e3e3'}
                      key={item.value}
                      value={item.value}
                      color={
                        multiSelectedValues.includes(item.value)
                          ? 'black'
                          : 'black'
                      }
                      bg={
                        multiSelectedValues.includes(item.value)
                          ? '#ffffe9'
                          : 'white'
                      }
                    >
                      {item.label} (Опционально)
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              )}
            </Menu>
          </Box>
        )}
        {isLastColumn ? <>{!multi ? node : null}</> : <>{node}</>}
      </Box>
    )
  }
  if (!selectedNames || !transformedData || !transformedData?.length)
    return null

  return (
    <Box
      border={'1px solid transparent'}
      p="3px 5px"
      borderRadius={'5px'}
      cursor={isPlannerEdit ? 'pointer' : 'default'}
      role="group"
      transition={'border 0.2s, box-shadow 0.2s'}
      onClick={isPlannerEdit ? () => handleClick() : null}
      _hover={
        isPlannerEdit && {
          border: '1px solid',
          borderColor: isDeleteHover ? 'red.500' : '#3182ce',
          boxShadow: isDeleteHover ? '0 0 0 1px red' : '0 0 0 1px #3182ce',
        }
      }
    >
      {isPlannerEdit && (
        <Box
          position="absolute"
          top={'-22px'}
          right={'-5px'}
          visibility="hidden"
          opacity={0}
          transition="visibility 0.2s, opacity 0.2s"
          _groupHover={{
            visibility: 'visible',
            opacity: 1,
          }}
        >
          <Flex>
            <Box
              p="1px 5px"
              pl="15px"
              _hover={{}}
              onMouseEnter={() => setIsDeleteHover(true)}
              onMouseLeave={() => setIsDeleteHover(false)}
              onClick={onDelete}
            >
              <Box
                color={'red.500'}
                _hover={{ color: 'red.600' }}
                fontSize={'18px'}
                bg="white"
                borderRadius={'50%'}
              >
                <HiMiniXCircle />
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
      <Cascader
        // color="#ff7300"
        parentSelectable
        value={value}
        onSelect={
          isPlannerEdit
            ? (node, selectedPaths) => handleSelect(node, selectedPaths)
            : null
        }
        data={transformedData}
        style={{ width: 'fit-content' }}
        searchable={false}
        renderColumn={(node, column) => renderMenu(node, column)}
        toggleAs={() => (
          <CustomToggle
            selectedNames={selectedNames}
            multiSelectedValues={multiSelectedValues}
            elementIndex={elementIndex}
            isPlannerEdit={isPlannerEdit}
          />
        )}
        popupClassName={clsx('custom-dropdown', { hidden: !isPlannerEdit })}
        container={() => document?.body}
      />
    </Box>
  )
}
