import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { useGetPuncemCustomFormulas } from 'entities/priceCategories'
import { Loader } from 'shared/ui'

import { EditCustomFormulas } from './EditCustomFormulas'

interface CustomFormulasListProps {
  puncem_user_id: string
  isEdit: boolean
}

export const CustomFormulasList: React.FC<CustomFormulasListProps> = memo(
  ({ puncem_user_id }): JSX.Element => {
    const { data, isLoading, isFetching } = useGetPuncemCustomFormulas({
      puncem_user_id,
      enabled: !!puncem_user_id,
    })

    return (
      <Box>
        {isLoading || isFetching ? (
          <Box m="10px" w="100%">
            <Loader size="sm" minHeight="30px" />
          </Box>
        ) : (
          <>
            {!data || (data && data.length === 0) ? (
              <Box p="10px">
                <Box opacity={'0.5'}>Данные отсутствуют</Box>
              </Box>
            ) : (
              <Box p="10px">
                {data &&
                  data.length > 0 &&
                  data.map((item) => {
                    const editCustomFormulasProps = {
                      ...item,
                      puncem_user_id,
                    }

                    return (
                      <EditCustomFormulas
                        key={item?.puncem_uf_id}
                        {...editCustomFormulasProps}
                      />
                    )
                  })}
              </Box>
            )}
          </>
        )}
      </Box>
    )
  },
)
