import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteCustomContract {
  puncem_user_id: string
  successAction: () => void
}
export function useDeleteCustomContract(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteCustomContract, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteCustomContract
  >(
    ({ puncem_user_id }: DeleteCustomContract) =>
      PuncemService.deletePuncemCustomContract({
        puncem_user_id,
      }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteCustomContract) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Карточка ГП удалена',
          description: `Карточка успешно удалена`,
        })

        queryClient.invalidateQueries([queryKeys.puncem.customContracts])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Карточка не была удалена',
        })
      },
    },
  )

  return { mutate, isLoading }
}
