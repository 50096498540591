import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface PuncemStandardListFiltersFormData {
  type_contract_id?: string
  price_category_id?: string
}

const SCHEMA_VALIDATION = yup.object().shape({})

export const useStandardListFiltersForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
  } = useForm<PuncemStandardListFiltersFormData>({
    resolver: yupResolver(SCHEMA_VALIDATION),
    mode: 'onChange',
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
  }
}
