import React, { useMemo, useState, useEffect, memo } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { Box, Flex, Text, Button } from '@chakra-ui/react'

import { DownloadReport } from 'features/prices'
import {
  DataViewATCHoursTable,
  Slice,
  Type,
  useGetDataViewATCHours,
} from 'entities/prices'
import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'
import { convertDateForAPI } from 'shared/utils'

import { Period } from '../types/types'
import { useDataViewATCHoursForm } from './DataViewATCHoursForm'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const DataViewATCHours: React.FC = memo(() => {
  const query = useQuery()

  const queryPeriod = query.get('period')
  const gsActivityAreasId = query.get('gs_activity_areas_id')

  const [slice, setSlice] = useState<Slice>(Slice.dateHour)
  const [type, setType] = useState<Type>(Type.br)
  const [fetchEnabled, setFetchEnabled] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })

  const { activityAreasData } = useGetAllActivityAreas({
    enabled: true,
  })

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
    isValid,
  } = useDataViewATCHoursForm()

  useEffect(() => {
    if (gsActivityAreasId && queryPeriod)
      reset({
        gs_activity_areas_id: gsActivityAreasId,
        date_period: moment(queryPeriod).format('DD.MM.yyyy'),
      })
  }, [gsActivityAreasId])

  const resetFetchState = () => {
    setFetchEnabled({
      enabled: false,
    })
  }

  const convertedDatePeriod: string = convertDateForAPI(
    watchedFields.date_period,
  )

  const {
    upload_info: uploadInfoViewATCHours,
    data: dataViewATCHours,
    isLoading: isLoadingDataViewATCHours,
    isFetching: isFetchingDataViewATCHours,
    refetch: refetchDataViewATCHours,
  } = useGetDataViewATCHours({
    enabled: fetchEnabled.enabled,
    gs_activity_areas_id: watchedFields.gs_activity_areas_id,
    date_period: convertedDatePeriod,
    successAction: () => resetFetchState(),
    errorAction: () => resetFetchState(),
  })

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleFetchData = () => refetchDataViewATCHours()

  const dataViewSVNCEMByHourProps = {
    type,
    setSlice,
    setType,
    slice,
    data: dataViewATCHours,
    upload_info: uploadInfoViewATCHours,
  }

  const downloadReportProps = {
    data: dataViewATCHours,
    isDisabled: false,
    gs_activity_areas_id: watchedFields.gs_activity_areas_id,
    date_period: convertDateForAPI(watchedFields.date_period),
    report_type: Period.peak,
  }

  return (
    <Box p="2">
      <form>
        <Box ml="10px">
          <Flex alignItems={'center'}>
            <Box mr="25px">
              <FormInputBlock
                titleWidth={'60px'}
                allowEdit={true}
                title={'Период'}
                edit={true}
              >
                <DatePickerField
                  setInitialDate
                  format="MM.yyyy"
                  showMonthYearPicker
                  name={'date_period'}
                  placeholder={'Период'}
                  type={'text'}
                  theme="monthPicker"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            <Box w={'480px'}>
              <FormInputBlock
                titleWidth={'180px'}
                allowEdit={true}
                edit
                title={'Зона деятельности ГП'}
              >
                <SelectInputForm
                  placeholder="Выберите зона деятельности ГП"
                  getOptionValue={(option) => (option ? option.value : '')}
                  name="gs_activity_areas_id"
                  data={activityAreasData || []}
                  isClearable={true}
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
          </Flex>
          <Box mt="20px">
            <Flex>
              <Box>
                <Button
                  w={'135px'}
                  position={'relative'}
                  size={'xs'}
                  color="white"
                  display={'flex'}
                  alignItems={'center'}
                  onClick={handleFetchData}
                  colorScheme="teal"
                  isDisabled={!isValid}
                  isLoading={
                    isLoadingDataViewATCHours || isFetchingDataViewATCHours
                  }
                >
                  <Box fontSize={'18px'} mr={'5px'}>
                    <HiOutlineTableCells />
                  </Box>
                  <Text position={'relative'}>Показать</Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Box>
        {dataViewATCHours && (
          <Box
            mt="15px"
            p="2"
            border={'1px solid #dfe0eb'}
            borderRadius={'5px'}
          >
            <DataViewATCHoursTable
              {...dataViewSVNCEMByHourProps}
              downloadReportSlot={
                <Box ml={'15px'}>
                  <DownloadReport {...downloadReportProps} />
                </Box>
              }
            />
          </Box>
        )}
      </form>
    </Box>
  )
})
