import React, { useContext } from 'react'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react'

import { PageScope } from 'app/providers'
import { BaseModal, FormInputControl } from 'shared/ui'

import { useSetNewPasswordForm } from './UseSetNewPasswordForm'
import { useSetNewPassword } from '../models/services/setNewPasswordService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'

interface ISetNewPassword {
  onClose: () => void
  isOpen: boolean
}

interface PasswordFormData {
  password: string
  password_confirmation: string
}

export const SetNewPasswordModal: React.FC<ISetNewPassword> = (props) => {
  const { onClose, isOpen } = props

  const { handleLogout } = useContext(PageScope)

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    isDirty,
    touchedFields,
    watchedFields,
  } = useSetNewPasswordForm()

  const { mutate: setNewPassword, isLoading: isUpdatingPassword } =
    useSetNewPassword()

  const onSubmit = ({ password, password_confirmation }: PasswordFormData) => {
    if (password !== password_confirmation) return
    setNewPassword({
      password,
      successAction: () => {
        onClose()
        handleLogout()
      },
    })
  }

  const handleCloseModal = () => {
    onClose()
    reset()
  }

  return (
    <BaseModal
      header="Установка нового пароля"
      id="delete user"
      isOpen={isOpen}
      onClose={handleCloseModal}
      // size={'large'}
    >
      <Box>
        <Flex justifyContent={'center'}>
          <Box w={'50px'} mb={'15px'}>
            <img src={warningLogo} alt="Warning" />
          </Box>
        </Flex>

        <Text
          fontWeight={'700'}
          fontSize={'14px'}
          mb={'5px'}
          color={'red.500'}
          textAlign={'center'}
        >
          После установки пароля вы будете перенаправлены на страницу входа.
          <br />
          Старый пароль больше не будет действителен.
        </Text>
        <Text fontWeight={'700'} mb={'5px'} mt={'15px'} textAlign={'center'}>
          Введите новый пароль и подтверждение пароля
        </Text>
        <Box mt={'35px'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex alignItems={'center'} mb={3} flexDirection={'column'}>
              <FormControl
                id="password"
                isInvalid={!!errors.password}
                display={'flex'}
                alignItems={'center'}
              >
                <FormLabel color="gray.400" minW={'140px'} m={0}>
                  Новый пароль:
                </FormLabel>
                <Flex flexDirection={'column'} w={'100%'}>
                  <FormInputControl
                    smallErrorTextInside
                    name="password"
                    register={register}
                    errors={errors}
                    watchedFields={watchedFields}
                    isRequired
                    placeholder="Новый пароль"
                    type="text"
                  />
                </Flex>
              </FormControl>
              <FormControl
                id="password_confirmation"
                isInvalid={!!errors.password_confirmation}
                display={'flex'}
                alignItems={'center'}
                mt={'15px'}
              >
                <FormLabel color="gray.400" minW={'140px'} m={0}>
                  Подтверждение пароля:
                </FormLabel>
                <Flex flexDirection={'column'} w={'100%'}>
                  <FormInputControl
                    smallErrorTextInside
                    name="password_confirmation"
                    register={register}
                    errors={errors}
                    watchedFields={watchedFields}
                    isRequired
                    placeholder="Подтверждение пароля"
                    type="text"
                  />
                </Flex>
              </FormControl>
            </Flex>
            <Flex justifyContent={'flex-end'} mt="35px">
              <Button fontWeight={'500'} mr="15px" onClick={handleCloseModal}>
                Отмена
              </Button>
              <Button
                type="submit"
                fontWeight={'500'}
                colorScheme="red"
                isDisabled={isUpdatingPassword}
                isLoading={isUpdatingPassword}
              >
                Сменить пароль
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
    </BaseModal>
  )
}
