import { useQuery } from 'react-query'

import {
  GetCustomPuncemFormulasRequest,
  GetCustomPuncemFormulasResponse,
} from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PuncemService } from 'shared/services'

interface UseGetPuncemCustomFormulasResult {
  data: GetCustomPuncemFormulasResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetPuncemCustomFormulas extends GetCustomPuncemFormulasRequest {
  enabled: boolean
  puncem_user_id: string
}

export function useGetPuncemCustomFormulas({
  enabled,
  puncem_user_id,
}: UseGetPuncemCustomFormulas): UseGetPuncemCustomFormulasResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GetCustomPuncemFormulasResponse[]
  >({
    queryKey: [queryKeys.puncem.customContractsFormulas, puncem_user_id],
    queryFn: () =>
      PuncemService.getPuncemCustomFormulas({ puncem_user_id }).then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  return { data, isError, isFetching, isLoading }
}
