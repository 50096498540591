import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import {
  ExtendedAxiosError,
  CreateStandardPuncemPeriodsRequest,
} from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface CreateStandardPeriodMutation
  extends CreateStandardPuncemPeriodsRequest {
  successAction?: () => void
  errorAction?: () => void
}

export function useCreateStandardPeriod(): {
  mutate: UseMutateFunction<AxiosResponse<undefined>, AxiosError, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ date_from, date_to }: CreateStandardPeriodMutation) =>
      PuncemService.createStandardPeriod({
        date_from,
        date_to,
      }),
    {
      onSuccess: async (
        data: AxiosResponse<undefined>,
        variables: CreateStandardPeriodMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Период успешно добвлен`,
          })
        }
        await queryClient.invalidateQueries([
          queryKeys.puncem.standardContractsPeriods,
        ])
        await queryClient.invalidateQueries([
          queryKeys.puncem.standardContracts,
        ])
      },
      onError: (
        error: ExtendedAxiosError,
        variables: CreateStandardPeriodMutation,
      ) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Период не был добавлен',
        })

        if (variables?.errorAction) variables?.errorAction()
      },
    },
  )
  return { mutate, isLoading }
}
