import { useQuery } from 'react-query'

import { ExtendedAxiosError, TypesConnectionResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface TypesConnectionsData {
  value: string
  label: string
}

interface UseGetTypesConnectionResult {
  data: TypesConnectionResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
  typesConnectionData: TypesConnectionsData[] | undefined
}

interface UseGetTypesConnection {
  enabled: boolean
  successAction?: () => void
  errorAction?: () => void
}

export function useGetTypesConnectionService({
  enabled,
  successAction,
  errorAction,
}: UseGetTypesConnection): UseGetTypesConnectionResult {
  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    TypesConnectionResponse[]
  >({
    queryKey: [queryKeys.prices.typesConnection],
    queryFn: () =>
      PriceCategoriesService.getTypesConnection().then(
        (response) => response.data,
      ),
    enabled,
    onError: (error: ExtendedAxiosError) => {
      if (errorAction) errorAction()
      toasts.error({
        title: 'Произошла ошибка',
        description:
          error?.response?.data?.detail || 'Данные не были загружены',
      })
    },
    onSuccess: () => {
      if (successAction) successAction()
    },
  })

  const typesConnectionData = data?.map((connection) => ({
    value: connection.type_connection_id,
    label: connection.type_connection_name,
  }))

  return {
    data: data,
    isError,
    isFetching,
    isLoading,
    refetch,
    typesConnectionData,
  }
}
