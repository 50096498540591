import { useQuery } from 'react-query'

import { ExtendedAxiosError, PriceCategoriesResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { PriceCategoriesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface PriceCategoriesData {
  value: string
  label: string
}

interface UseGetPriceCategoriesResult {
  data: PriceCategoriesResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
  priceCategoriesData: PriceCategoriesData[] | undefined
}

interface UseGetPriceCategories {
  enabled: boolean
  successAction?: () => void
  errorAction?: () => void
}

export function useGetPriceCategoriesService({
  enabled,
  successAction,
  errorAction,
}: UseGetPriceCategories): UseGetPriceCategoriesResult {
  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    PriceCategoriesResponse[]
  >({
    queryKey: [queryKeys.prices.priceCategories],
    queryFn: () =>
      PriceCategoriesService.getPriceCategories().then(
        (response) => response.data,
      ),
    enabled,
    onError: (error: ExtendedAxiosError) => {
      if (errorAction) errorAction()
      toasts.error({
        title: 'Произошла ошибка',
        description:
          error?.response?.data?.detail || 'Данные не были загружены',
      })
    },
    onSuccess: () => {
      if (successAction) successAction()
    },
  })

  const priceCategoriesData = data?.map((tariff) => ({
    value: tariff.price_category_id,
    label: tariff.category,
  }))

  return {
    data: data,
    isError,
    isFetching,
    isLoading,
    refetch,
    priceCategoriesData,
  }
}
