import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteStandardPeriod {
  puncem_period_id: string
  successAction: () => void
}
export function useDeleteStandardPeriod(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteStandardPeriod, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteStandardPeriod
  >(
    ({ puncem_period_id }: DeleteStandardPeriod) =>
      PuncemService.deleteStandardPeriod({
        puncem_period_id,
      }).then(() => {}),
    {
      onSuccess: async (_, variables: DeleteStandardPeriod) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Период удалена',
          description: `Период успешно удален`,
        })

        await queryClient.invalidateQueries([
          queryKeys.puncem.standardContractsPeriods,
        ])
        await queryClient.invalidateQueries([
          queryKeys.puncem.standardContracts,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Период не был удален',
        })
      },
    },
  )

  return { mutate, isLoading }
}
