import React, { useCallback, useMemo } from 'react'
import { Box, Card, Collapse } from '@chakra-ui/react'

import {
  CardBox,
  FormInputBlock,
  FormInputControl,
  SubmitCancelButtons,
  TextTitle,
} from 'shared/ui'

import { useCreateCustomContractForm } from './CreateCustomContractForm'
import { useCreateCustomContract } from '../models/service/createCustomContractServie'

interface CreateCustomContractProps {
  isOpen: boolean
  onToggle: () => void
}

export const CreateCustomContract: React.FC<CreateCustomContractProps> = ({
  isOpen,
  onToggle,
}): JSX.Element => {
  const { mutate: createCustomContract, isLoading } = useCreateCustomContract()
  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
    isValid,
    getValues,
  } = useCreateCustomContractForm()

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleResetForm = useCallback(() => {
    reset()
    onToggle()
  }, [reset, onToggle])

  const onSubmit = () => {
    const formData = getValues()

    createCustomContract({
      puncem_name: formData?.puncem_name,
      successAction: () => {
        handleResetForm()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Collapse in={isOpen} animateOpacity style={{ overflow: 'inherit' }}>
        <Box p="10px" pb="0">
          <TextTitle size="small">Добавление новой карточки</TextTitle>
        </Box>
        <CardBox p="10px" m="10px">
          <Card
            bg="#eef1fc45"
            p="10px"
            boxShadow={'none'}
            variant={'outline'}
            position={'relative'}
          >
            <Box w="500px">
              <FormInputBlock
                titleWidth={'120px'}
                allowEdit={true}
                edit={true}
                value={''}
                title={'Наименование:'}
              >
                <FormInputControl
                  name="puncem_name"
                  placeholder="Наименование"
                  type="text"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>
            {isDirty && (
              <Box mt={'15px'}>
                <SubmitCancelButtons
                  isDirty={isDirty}
                  isUpdating={isLoading}
                  handleCancel={handleResetForm}
                  isDisabled={!isValid}
                />
              </Box>
            )}
          </Card>
        </CardBox>
      </Collapse>
    </form>
  )
}
