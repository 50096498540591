import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { PuncemService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteCustomFormula {
  puncem_uf_id: string
  puncem_user_id: string
  successAction: () => void
}
export function useDeleteCustomFormula(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteCustomFormula, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteCustomFormula
  >(
    ({ puncem_uf_id }: DeleteCustomFormula) =>
      PuncemService.deleteCustomFormula({
        puncem_uf_id,
      }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteCustomFormula) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Формула удалена',
          description: `Формула расчета успешно удалена`,
        })

        queryClient.invalidateQueries([
          queryKeys.puncem.customContractsFormulas,
          variables?.puncem_user_id,
        ])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Формула расчета не была удалена',
        })
      },
    },
  )

  return { mutate, isLoading }
}
