import { Resolver, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export interface EditStandardFormulasFormData {
  name?: string
  default_product?: string[]
  formula_json?: unknown
}

const SCHEMA_VALIDATION = yup.object().shape({
  name: yup.string().required('Имя обязательное поле'),
  default_product: yup
    .array()
    .of(yup.string())
    .min(1, 'Товар по-умолчанию обязательное поле')
    .required('Товар по-умолчанию обязательное поле'),
  formula_json: yup
    .array()
    .min(1, 'Поле formula_json не может быть пустым')
    .required('Поле formula_json обязательно'),
})

export const useEditStandardFormulasForm = () => {
  const {
    setValue,
    register,
    handleSubmit,
    formState,
    resetField,
    reset,
    control,
    watch,
    getValues,
    setError,
    trigger,
  } = useForm<EditStandardFormulasFormData>({
    resolver: yupResolver(
      SCHEMA_VALIDATION,
    ) as Resolver<EditStandardFormulasFormData>,
    mode: 'onChange',
    defaultValues: {
      name: null,
      default_product: [],
      formula_json: null,
    },
  })

  const watchedFields = watch()

  return {
    reset,
    register,
    handleSubmit,
    errors: formState.errors,
    isDirty: formState.isDirty,
    isValid: formState.isValid,
    isSubmitting: formState.isSubmitting,
    control,
    resetField,
    setValue,
    watchedFields,
    getValues,
    watch,
    setError,
    trigger,
  }
}
