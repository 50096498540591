import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Card,
  Collapse,
  Flex,
  FormLabel,
  Switch,
  Text,
} from '@chakra-ui/react'

import { Access, useAccess } from 'app/providers'
import { StandardFormulasList } from 'features/priceCategories'
import { useGetTypesContracts } from 'entities/counteragentsContracts'
import {
  useGetLossesTypesService,
  useGetPriceCategoriesService,
  useGetTariffSubTypesService,
  useGetTariffTypesService,
  useGetTypesConnectionService,
} from 'entities/prices'
import { useGetPuncemStandardContractPeriods } from 'entities/priceCategories'

import {
  GetStandardPuncemResponse,
  UpdateStandardPuncemRequest,
} from 'shared/models'

import {
  CardBox,
  EditButton,
  FormInputBlock,
  SelectInputForm,
  TextTitle,
} from 'shared/ui'

import { useEditStandardContractForm } from './EditStandartdContractForm'
import { useUpdateStandardContract } from '../models/service/useUpdateStandardContract'
import { CreateStandardFormula } from '../../createStandardFormula'

// misc
import { MdOutlineSave } from 'react-icons/md'
import { HiXMark } from 'react-icons/hi2'
import { ROLES } from 'shared/constants'

export interface EditStandardContractProps extends GetStandardPuncemResponse {
  deleteSlot: React.ReactNode
}

export const EditStandardContract: React.FC<EditStandardContractProps> = memo(
  ({
    type_contract_id,
    type_connection_id,
    tariff_type_id,
    tariff_subtype_id,
    price_category_id,
    type_loss_id,
    transmission_service,
    price_category,
    puncem_standard_id,
    deleteSlot,
    puncem_period_id,
  }): JSX.Element => {
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const [initialState, setInitialState] =
      useState<UpdateStandardPuncemRequest>({
        type_contract_id,
        type_connection_id,
        tariff_type_id,
        tariff_subtype_id,
        price_category_id,
        type_loss_id,
        transmission_service,
        price_category,
        puncem_standard_id,
        puncem_period_id,
      })

    useEffect(() => {
      setInitialState({
        type_contract_id,
        transmission_service,
        type_connection_id,
        tariff_type_id,
        tariff_subtype_id,
        price_category,
        price_category_id,
        type_loss_id,
        puncem_standard_id,
        puncem_period_id,
      })
    }, [
      type_contract_id,
      transmission_service,
      type_connection_id,
      tariff_type_id,
      tariff_subtype_id,
      price_category,
      price_category_id,
      type_loss_id,
      puncem_standard_id,
      puncem_period_id,
    ])

    const { hasAccess } = useAccess()

    const isUserAbleToEdit: boolean = hasAccess({
      roles: [ROLES.SUPERADMIN],
    })

    const { mutate: updateStandardContract, isLoading } =
      useUpdateStandardContract()

    const { typesContractsData = [] } = useGetTypesContracts({
      enabled: true,
    })

    const { typesConnectionData = [] } = useGetTypesConnectionService({
      enabled: true,
    })

    const { tariffTypesData = [] } = useGetTariffTypesService({
      enabled: true,
    })

    const { tariffSubTypesData = [] } = useGetTariffSubTypesService({
      enabled: true,
    })

    const { priceCategoriesData = [] } = useGetPriceCategoriesService({
      enabled: true,
    })

    const { lossesTypesData = [] } = useGetLossesTypesService({
      enabled: true,
    })

    const { puncemStandardContractsData = [] } =
      useGetPuncemStandardContractPeriods({
        enabled: true,
      })

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
      getValues,
    } = useEditStandardContractForm()

    useEffect(() => {
      reset(initialState)
    }, [initialState])

    const transmissionServicesEnabled = watchedFields?.transmission_service
    const pricesCategoryEnabled = watchedFields?.price_category

    useEffect(() => {
      if (transmissionServicesEnabled === false) {
        setValue('type_connection_id', null, { shouldDirty: true })
        setValue('tariff_type_id', null, { shouldDirty: true })
        setValue('tariff_subtype_id', null, { shouldDirty: true })
      }
    }, [transmissionServicesEnabled, setValue])

    useEffect(() => {
      if (pricesCategoryEnabled === false) {
        setValue('price_category_id', null, { shouldDirty: true })
        setValue('type_loss_id', null, { shouldDirty: true })
      }
    }, [pricesCategoryEnabled, setValue])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const onSubmit = () => {
      const formData = getValues() as UpdateStandardPuncemRequest

      updateStandardContract({
        ...formData,
        puncem_standard_id,
        successAction: () => {
          setInitialState({
            ...initialState,
            ...formData,
          })
          // handleEditClick()
        },
      })
    }

    const handleResetForm = () => reset()

    const handleTransmissionServicesSwitchChange = (checked: boolean) =>
      setValue('transmission_service', checked)

    const handlePricesCategorySwitchChange = (checked: boolean) =>
      setValue('price_category', checked)

    const handleEditClick = useCallback(() => {
      setIsEdit(!isEdit)
    }, [isEdit])

    return (
      <form>
        <CardBox
          p="10px"
          _hover={{
            bg: isEdit ? '#f8fde61d' : '#eef1fc68',
            border: '1px solid #11b8a284',
            shadow: 'md',
          }}
          transition={'all 0.2s ease-in-out'}
        >
          <Card
            bg={isEdit ? '#0083fd13' : '#fdfdfd'}
            p="10px"
            boxShadow={'none'}
            variant={'outline'}
            position={'relative'}
            _groupHover={{ bg: '#eef1fc45' }}
            transition={'all 0.2s ease-in-out'}
          >
            <Flex
              w="auto"
              justifyContent="flex-end"
              position={'absolute'}
              right={'20px'}
              top={'10px'}
              zIndex={1}
            >
              <EditButton
                handleEditClick={handleEditClick}
                isEdit={isEdit}
                size={'xs'}
              />
            </Flex>

            <Box w="500px">
              <FormInputBlock
                titleWidth={'150px'}
                allowEdit={true}
                title={'Тип договора'}
                edit={isEdit && isUserAbleToEdit}
                value={
                  typesContractsData.find(
                    (typesContractsData) =>
                      typesContractsData.value === type_contract_id,
                  )?.label
                }
              >
                <SelectInputForm
                  placeholder="Выберите тип договора"
                  isRequired
                  data={typesContractsData}
                  isCreatable={false}
                  isClearable={true}
                  getOptionValue={(option) => (option ? option.value : '')}
                  name="type_contract_id"
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>

            <hr style={{ margin: '8px 0' }} />

            <Flex alignItems={'center'} mt="5px">
              <FormLabel
                fontSize={'14px'}
                color={'gray.500'}
                fontWeight={'500'}
                mb="0"
                mr="20px"
              >
                Услуги по передаче
              </FormLabel>
              <Switch
                isReadOnly={!isEdit || !isUserAbleToEdit}
                isDisabled={!isEdit || !isUserAbleToEdit}
                size={'sm'}
                id={`1`}
                isChecked={transmissionServicesEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleTransmissionServicesSwitchChange(e.target.checked)
                }
              />
            </Flex>
            <Flex alignItems={'center'} mt="10px">
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'150px'}
                  allowEdit={true}
                  title={'Тип присоеденения'}
                  edit={isEdit && isUserAbleToEdit}
                  value={
                    typesConnectionData.find(
                      (typesConnectionData) =>
                        typesConnectionData.value ===
                        initialState?.type_connection_id,
                    )?.label
                  }
                >
                  <SelectInputForm
                    isRequired={false}
                    isDisabled={transmissionServicesEnabled === false}
                    isReadOnly={transmissionServicesEnabled === false}
                    placeholder="Выберите тип присоеденения"
                    data={typesConnectionData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="type_connection_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'100px'}
                  allowEdit={true}
                  title={'Тип тарифа'}
                  edit={isEdit && isUserAbleToEdit}
                  value={
                    tariffTypesData.find(
                      (tariffTypesData) =>
                        tariffTypesData.value === initialState?.tariff_type_id,
                    )?.label
                  }
                >
                  <SelectInputForm
                    isDisabled={transmissionServicesEnabled === false}
                    isReadOnly={transmissionServicesEnabled === false}
                    placeholder="Выберите тип тарифа"
                    data={tariffTypesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="tariff_type_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'130px'}
                  allowEdit={true}
                  title={'Подтип тарифа'}
                  edit={isEdit && isUserAbleToEdit}
                  value={
                    tariffSubTypesData.find(
                      (tariffSubTypesData) =>
                        tariffSubTypesData.value ===
                        initialState?.tariff_subtype_id,
                    )?.label
                  }
                >
                  <SelectInputForm
                    isDisabled={transmissionServicesEnabled === false}
                    isReadOnly={transmissionServicesEnabled === false}
                    placeholder="Выберите подтип тарифа"
                    data={tariffSubTypesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="tariff_subtype_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <hr style={{ margin: '8px 0' }} />

            <Flex alignItems={'center'} mt="5px">
              <FormLabel
                fontSize={'14px'}
                color={'gray.500'}
                fontWeight={'500'}
                mb="0"
                mr="20px"
              >
                Ценовые категории
              </FormLabel>
              <Switch
                isReadOnly={!isEdit || !isUserAbleToEdit}
                isDisabled={!isEdit || !isUserAbleToEdit}
                size={'sm'}
                id={`2`}
                isChecked={watchedFields?.price_category}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePricesCategorySwitchChange(e.target.checked)
                }
              />
            </Flex>

            <Flex alignItems={'center'} mt="10px">
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'150px'}
                  allowEdit={true}
                  title={'Ценовая категория'}
                  edit={isEdit && isUserAbleToEdit}
                  value={
                    priceCategoriesData.find(
                      (priceCategoriesData) =>
                        priceCategoriesData.value ===
                        initialState?.price_category_id,
                    )?.label
                  }
                >
                  <SelectInputForm
                    isDisabled={pricesCategoryEnabled === false}
                    isReadOnly={pricesCategoryEnabled === false}
                    placeholder="Выберите ценовую категоию"
                    data={priceCategoriesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="price_category_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w="500px" mr="15px">
                <FormInputBlock
                  titleWidth={'100px'}
                  allowEdit={true}
                  title={'Вид потерь'}
                  edit={isEdit && isUserAbleToEdit}
                  value={
                    lossesTypesData?.find(
                      (lossesTypesData) =>
                        lossesTypesData.value === initialState?.type_loss_id,
                    )?.label
                  }
                >
                  <SelectInputForm
                    isDisabled={pricesCategoryEnabled === false}
                    isReadOnly={pricesCategoryEnabled === false}
                    placeholder="Выберите вид потерь"
                    data={lossesTypesData}
                    isCreatable={false}
                    isClearable={true}
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="type_loss_id"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <hr style={{ margin: '8px 0' }} />

            <Collapse in={isEdit} animateOpacity>
              <Flex alignItems={'center'} mt="5px">
                <Box w="500px" mr="15px">
                  <FormInputBlock
                    titleWidth={'150px'}
                    allowEdit={true}
                    title={'Период действия'}
                    edit={isEdit}
                    value={
                      puncemStandardContractsData.find(
                        (puncemStandardContractsData) =>
                          puncemStandardContractsData.value ===
                          initialState?.puncem_period_id,
                      )?.label
                    }
                  >
                    <SelectInputForm
                      placeholder="Выберите период"
                      data={puncemStandardContractsData}
                      isCreatable={false}
                      isClearable={true}
                      getOptionValue={(option) => (option ? option.value : '')}
                      name="puncem_period_id"
                      {...commonInputProps}
                    />
                  </FormInputBlock>
                </Box>
              </Flex>
              <hr style={{ margin: '8px 0' }} />
              <Card boxShadow={'none'} variant={'outline'}>
                <Box alignItems={'center'}>
                  <Flex p="10px">
                    <TextTitle size="small">Формулы расчета</TextTitle>
                    <Access roles={[ROLES.SUPERADMIN]}>
                      <CreateStandardFormula
                        puncem_standard_id={puncem_standard_id}
                        puncem_period_id={watchedFields?.puncem_period_id}
                      />
                    </Access>
                  </Flex>
                  <hr style={{ margin: 0 }} />
                </Box>
                <StandardFormulasList
                  puncem_standard_id={puncem_standard_id}
                  puncem_period_id={watchedFields?.puncem_period_id}
                  isEdit={isEdit}
                />
              </Card>
            </Collapse>
            <Access roles={[ROLES.SUPERADMIN]}>
              <Flex alignItems={'center'} mt="15px">
                {isEdit && isDirty && (
                  <>
                    <Button
                      size="xs"
                      type="submit"
                      isLoading={isLoading}
                      isDisabled={!isValid}
                      bg="teal.400"
                      color="white"
                      display="flex"
                      alignItems="center"
                      _hover={{
                        bg: 'teal.500',
                      }}
                      onClick={() => onSubmit()}
                    >
                      Сохранить карточку
                      <Box fontSize={'18px'} ml={'5px'}>
                        <MdOutlineSave />
                      </Box>
                    </Button>
                    <Button
                      ml="10px"
                      size="xs"
                      onClick={() => {
                        handleEditClick()
                        handleResetForm()
                      }}
                    >
                      Отмена
                      <Box fontSize={'18px'} ml={'5px'}>
                        <HiXMark />
                      </Box>
                    </Button>
                  </>
                )}
                {isEdit && deleteSlot}
              </Flex>
            </Access>
          </Card>
        </CardBox>
      </form>
    )
  },
)
