import { memo, useEffect, useState } from 'react'
import { Box, Collapse } from '@chakra-ui/react'

import {
  CreateCustomContract,
  DeleteCustomContract,
  EditCustomContract,
} from 'features/priceCategories'
import { useGetPuncemCustomContracts } from 'entities/priceCategories'
import { Loader } from 'shared/ui'
import { GetCustomPuncemResponse } from 'shared/models'

import { PuncemCustomListFilters } from './PuncemCustomListFilters'

interface PuncemCustomListProps {
  isOpenCustomFilters: boolean
  onToggleCustomFilters: () => void
  onCreateCustomToggle: () => void
  isCreateCustomOpen: boolean
  filters: string | null
  setFilters: React.Dispatch<React.SetStateAction<string>>
}

export const PuncemCustomList: React.FC<PuncemCustomListProps> = memo(
  ({
    isOpenCustomFilters,
    isCreateCustomOpen,
    onCreateCustomToggle,
    filters,
    setFilters,
  }): JSX.Element => {
    const [originalList, setOriginalList] = useState<GetCustomPuncemResponse[]>(
      [],
    )
    const [filteredList, setFilteredList] = useState<GetCustomPuncemResponse[]>(
      [],
    )

    const { data: puncemCustomList = [], isLoading } =
      useGetPuncemCustomContracts({
        enabled: true,
      })

    useEffect(() => {
      setOriginalList(puncemCustomList)
    }, [puncemCustomList])

    useEffect(() => {
      if (!filters) {
        setFilteredList(originalList)
        return
      }
      // const filtered = originalList.filter((item) => {
      //   const matchesTypeContract =
      //     !filters.type_contract_id ||
      //     item?.type_contract_id === filters.type_contract_id
      //   const matchesPriceCategory =
      //     !filters.price_category_id ||
      //     item?.price_category_id === filters.price_category_id
      //   return matchesTypeContract && matchesPriceCategory
      // })
      // setFilteredList(filtered)
    }, [filters, originalList])

    return (
      <Box>
        <Box
          position="relative"
          zIndex="1"
          boxShadow={
            isCreateCustomOpen ? '0px 2px  5px rgba(0, 0, 0, 0.12)' : 'none '
          }
          borderTop={'1px solid #319795'}
          bg="#f7f8fb79"
          pb="5px"
        >
          <CreateCustomContract
            isOpen={isCreateCustomOpen}
            onToggle={onCreateCustomToggle}
          />
        </Box>
        <Box pt="0">
          <Collapse
            in={isOpenCustomFilters}
            style={{
              overflow: 'inherit',
            }}
          >
            <PuncemCustomListFilters
              filters={filters}
              setFilters={setFilters}
            />
          </Collapse>

          <Box
            bg="#fdfdfd"
            p="10px"
            position={'relative'}
            h={'82vh'}
            pb="500px"
            overflowY="scroll"
            borderBottom={'1px solid #eeeeee'}
            borderRadius={'6px'}
          >
            {isLoading ? (
              <Box m="150px 0">
                <Loader size="md" />
              </Box>
            ) : (
              <>
                {filteredList.length === 0 ? (
                  <Box m="0">
                    <Box opacity={'0.5'}>Ничего не найдено</Box>
                  </Box>
                ) : (
                  <>
                    {filteredList.map((item, index) => (
                      <Box key={item?.puncem_user_id} mb="15px">
                        <EditCustomContract
                          {...item}
                          deleteSlot={
                            <DeleteCustomContract
                              puncem_user_id={item?.puncem_user_id}
                              puncem_name={item?.puncem_name}
                            />
                          }
                        />
                      </Box>
                    ))}
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    )
  },
)
