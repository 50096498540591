import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Card,
  Text,
  Flex,
  useDisclosure,
  AlertIcon,
  Alert,
  CloseButton,
  Collapse,
} from '@chakra-ui/react'

import { Access } from 'app/providers'
import {
  CreateStandardPeriod,
  EditStandardPeriod,
} from 'features/priceCategories'
import { useGetPuncemStandardContractPeriods } from 'entities/priceCategories'
import { Loader } from 'shared/ui'
import { ROLES } from 'shared/constants'
import { accessDict, modulesDict } from 'shared/dictionary'

// misc
import { HiMiniPlusCircle } from 'react-icons/hi2'

interface StandardContractPeriodsProps {
  isOpen: boolean
  onToggle: () => void
  onClosePeriods: () => void
}

export const StandardContractPeriods: React.FC<
  StandardContractPeriodsProps
> = ({ isOpen, onToggle }): JSX.Element => {
  const [isOpenCreatePeriod, setIsOpenCreatePeriod] = useState<boolean>(false)

  const { isOpen: isVisibleAlert, onClose: onCloseAlert } = useDisclosure({
    defaultIsOpen: true,
  })

  const ref = React.useRef<HTMLDivElement>(null)

  const { isLoading, data } = useGetPuncemStandardContractPeriods({
    enabled: true,
  })

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onToggle()
          setIsOpenCreatePeriod(false)
        }}
        size={'xl'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent
          ref={ref}
          sx={{
            width: '600px',
            maxWidth: 'none',
          }}
        >
          <ModalHeader borderBottom={'1px solid #dfe0eb'}>
            <Box fontSize={'18px'}>Список периодов</Box>
          </ModalHeader>

          <ModalCloseButton
            onClick={() => {
              onToggle()
              setIsOpenCreatePeriod(false)
            }}
          />
          <ModalBody>
            <Collapse in={isVisibleAlert} animateOpacity>
              <Alert
                status="info"
                p="5px 10px"
                borderRadius={'5px'}
                mb="15px"
                color="#02274a"
              >
                <AlertIcon />
                Список периодов применяется к стандартным ПУНЦЭМ и служит для
                учета изменений в законодательстве.
                <CloseButton
                  alignSelf="flex-start"
                  position="relative"
                  top={2}
                  onClick={onCloseAlert}
                  size={'sm'}
                />
              </Alert>
            </Collapse>

            <Card p="5px" boxShadow={'none'} variant={'outline'}>
              {isLoading ? (
                <Box w="100%" h="200px">
                  <Loader size="md" />
                </Box>
              ) : (
                <>
                  {data.length === 0 ? (
                    <Box m="0">
                      <Box opacity={'0.5'}>Ничего не найдено</Box>
                    </Box>
                  ) : (
                    <>
                      {data?.map((period, index) => {
                        return (
                          <Box key={period.puncem_period_id}>
                            <EditStandardPeriod
                              {...period}
                              containerRef={ref}
                            />
                          </Box>
                        )
                      })}
                    </>
                  )}
                </>
              )}
            </Card>
          </ModalBody>

          <ModalFooter borderTop={'1px solid #dfe0eb'}>
            <Access roles={[ROLES.SUPERADMIN]}>
              <Flex flexDir={'column'} w="100%">
                <CreateStandardPeriod
                  isOpenCreatePeriod={isOpenCreatePeriod}
                  setIsOpenCreatePeriod={setIsOpenCreatePeriod}
                />
                <Flex ml="auto" mt="15px">
                  <Button
                    isDisabled={isOpenCreatePeriod}
                    mr="10px"
                    position={'relative'}
                    size={'xs'}
                    bg="blue.700"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={() => setIsOpenCreatePeriod(true)}
                    _hover={{
                      bg: 'blue.600',
                    }}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiMiniPlusCircle />
                    </Box>
                    <Text position={'relative'}>Добавить период</Text>
                  </Button>

                  <Button
                    variant="ghost"
                    size={'xs'}
                    onClick={() => {
                      onToggle()
                      setIsOpenCreatePeriod(false)
                    }}
                  >
                    Закрыть
                  </Button>
                </Flex>
              </Flex>
            </Access>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
