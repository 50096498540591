import { Box } from '@chakra-ui/react'
import _ from 'lodash'

import { mockGroupedData } from './data'
import { FormulaBuilder } from './FormulaBuilder'
// import { CascadePlanner } from './FormulaBuilder/CascadePlanner'
import { CascadePlannerDragAndDrop } from './FormulaBuilder/CascadePlannerDragAndDrop'
import { useGetProductsThreeService } from 'entities/prices'
import { CascadePlanner } from 'shared/ui'
import { useState } from 'react'

interface FormulaJson {
  autoFocus: boolean
  editable: boolean
  multi: boolean
  elementId: string
  multiSelectedValues: string[]
  selectedNames: string[]
  type: number
  value: string
}

const testData = [
  {
    type: 1,
    multi: false,
    value: '',
    editable: true,
    autoFocus: true,
    elementId: 'e957c51c-a311-4d4c-90c3-4e19160a910e',
    selectedNames: ['Товар', 'Электроэнергия', 'Месяц', 'Факт'],
    multiSelectedValues: [],
  },
  {
    type: 0,
    multi: false,
    value: '+(',
    editable: false,
    autoFocus: false,
    elementId: '87c221d6-0f45-4ec9-9596-e1cb46f0421b',
    selectedNames: [],
    multiSelectedValues: [],
  },
  {
    type: 1,
    multi: false,
    value: '',
    editable: true,
    autoFocus: true,
    elementId: 'e69c8e6e-6c1d-4108-9719-ef6323d49b57',
    selectedNames: ['СВНЦЭМ', 'СВНЦМ'],
    multiSelectedValues: [],
  },
  {
    type: 0,
    multi: false,
    value: '*',
    editable: false,
    autoFocus: false,
    elementId: '67cfc045-b68d-4a63-9535-20270e580b5b',
    selectedNames: [],
    multiSelectedValues: [],
  },
  {
    type: 1,
    multi: false,
    value: '',
    editable: true,
    autoFocus: true,
    elementId: '91cc1117-ac36-4da8-b3de-752a44ea815a',
    selectedNames: ['Сбытовая надбавка', 'Менее 670 кВт'],
    multiSelectedValues: [],
  },
  {
    type: 0,
    multi: false,
    value: ')',
    editable: false,
    autoFocus: false,
    elementId: 'c4c3092c-1f6f-4b5e-926b-a9b2dd346945',
    selectedNames: [],
    multiSelectedValues: [],
  },
  {
    type: 0,
    value: '+',
    selectedNames: [],
    multiSelectedValues: [],
    elementId: '82513d7a-fc51-411d-9b32-9d54c524bc10',
    editable: false,
    multi: false,
    autoFocus: false,
  },
]

export const TestPage2 = () => {
  const { data } = useGetProductsThreeService({ enabled: true })

  const [formulaPlannerElements, setFormulaPlannerElements] =
    useState<FormulaJson[]>(testData)

  const formulaPlannerProps = {
    elements: formulaPlannerElements,
    setElements: setFormulaPlannerElements,
  }

  console.log('TEST PAGE', formulaPlannerElements)

  return (
    <div>
      <h1>TestPage2</h1>
      <Box m={'5'}>{/* <CascadePlanner data={mockGroupedData} /> */}</Box>
      <br />
      <br />
      <Box m={'5'}>
        {/* <CascadePlannerDragAndDrop threeData={data || []} /> */}
        {/* <CascadePlanner threeData={data || []} {...formulaPlannerProps} /> */}
      </Box>

      <br />
      <br />
      <FormulaBuilder />
    </div>
  )
}
// #4da06d
