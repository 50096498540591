import React, { memo } from 'react'
import { Box, Button, Flex, Menu, MenuButton, Text } from '@chakra-ui/react'

import { Access } from 'app/providers'
import { accessDict, modulesDict } from 'shared/dictionary'

// misc
import {
  HiMiniPlusCircle,
  HiMiniXMark,
  HiOutlineCog6Tooth,
} from 'react-icons/hi2'
import { LuFilter } from 'react-icons/lu'

interface PuncemCustomActionsProps {
  onToggle: () => void
  onToggleCustomFilters: () => void
  isOpen: boolean
  filters: string | null
  setFilters: React.Dispatch<React.SetStateAction<string>>
  onTogglePeriods: () => void
  isOpenPeriods: boolean
  isOpenCustomFilters: boolean
}

export const PuncemCustomActions: React.FC<PuncemCustomActionsProps> = memo(
  ({
    onToggle,
    isOpen,
    isOpenCustomFilters,
    onToggleCustomFilters,
    filters,
    onTogglePeriods,
  }): JSX.Element => {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'flex-end'}>
        <Access
          permissions={[accessDict.update_read]}
          module={modulesDict.reference_books}
        >
          <Box mr={'5px'}>
            {isOpen ? (
              <Button
                position={'relative'}
                size={'xs'}
                display={'flex'}
                alignItems={'center'}
                onClick={() => onToggle()}
              >
                <Box fontSize={'18px'}>
                  <HiMiniXMark />
                </Box>
                <Text position={'relative'}>Отмена</Text>
              </Button>
            ) : (
              <Button
                position={'relative'}
                size={'xs'}
                bg="blue.700"
                color="white"
                display={'flex'}
                alignItems={'center'}
                onClick={() => onToggle()}
                _hover={{
                  bg: 'blue.600',
                }}
              >
                <Box fontSize={'18px'} mr={'5px'}>
                  <HiMiniPlusCircle />
                </Box>
                <Text position={'relative'}>
                  Добавить пользовательскую карточку
                </Text>
              </Button>
            )}
          </Box>
        </Access>

        <Box mr="5px">
          <Menu>
            <MenuButton
              _hover={{ color: 'teal.700', bg: 'gray.200' }}
              size={'xs'}
              padding={'0 7px'}
              as={Button}
              cursor={'pointer'}
              minW={0}
              onClick={() => onTogglePeriods()}
            >
              <Box
                _hover={{ bg: 'transparent' }}
                aria-label="Toggle Color Mode"
                _focus={{ boxShadow: 'none' }}
                w="fit-content"
              >
                <HiOutlineCog6Tooth size={'18px'} />
              </Box>
            </MenuButton>
          </Menu>
        </Box>

        <Box mr="5px" position={'relative'}>
          {filters && (
            <Flex
              w={'16px'}
              h={'16px'}
              bg={'red'}
              color="white"
              borderRadius={'50%'}
              position={'absolute'}
              zIndex={2}
              alignItems={'center'}
              justifyContent={'center'}
              top={'-8px'}
              right={'-11px'}
              fontSize={'12px'}
            >
              1
            </Flex>
          )}
          <Button
            _hover={
              isOpenCustomFilters
                ? {
                    color: filters ? 'black' : 'white',
                    bg: filters ? '#e7f525' : 'green.400',
                  }
                : { color: 'teal.700', bg: 'gray.200' }
            }
            bg={
              filters
                ? 'yellow'
                : isOpenCustomFilters
                ? 'green.500'
                : 'gray.100'
            }
            color={
              filters ? 'teal.900' : isOpenCustomFilters ? 'white' : 'teal.900'
            }
            size={'xs'}
            padding={'0 7px'}
            as={Button}
            cursor={'pointer'}
            minW={0}
            onClick={() => onToggleCustomFilters()}
          >
            <Box _focus={{ boxShadow: 'none' }} w="fit-content" m="2px">
              <LuFilter size={'14px'} />
            </Box>
          </Button>
        </Box>
      </Flex>
    )
  },
)
